import { FC } from 'react';
import { useDraggable } from '@dnd-kit/core';
import { PropsWithChildren } from 'react';

type DraggableProps = {
  id: string,
  data: any;
  // onDrop: (id: string, index: number) => void;
  // onDragStart: (id: string) => void;
}

const Draggable: FC<DraggableProps & PropsWithChildren> = ({ id, data, children }: DraggableProps & PropsWithChildren) => {
  const { setNodeRef, attributes, listeners, transform } = useDraggable(
    {
      id,
      data,
    }
  );

  const style = undefined

  return (
    <div ref={setNodeRef} {...attributes} {...listeners} style={style}>
      {children}
    </div>
  );
}

export default Draggable;

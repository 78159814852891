import { Button } from 'primereact/button'
import { PropsWithChildren } from 'react'
import { LoadingIcon, SaveIcon } from '../Icons'

type SaveButtonProps = {
  title?: string
  label?: string
  loading?: boolean
  disabled?: boolean
  onClick?: () => void
  dataPrAt?: string
}

const SaveButton = ({ title, label, disabled, loading, onClick, dataPrAt }: SaveButtonProps & PropsWithChildren) => {
  if (dataPrAt === undefined) {
    dataPrAt = 'center+5 bottom'
  }

  return (
    <Button
      onClick={onClick}
      severity="secondary"
      className="icon-button cursor-pointer"
      type="submit"
      disabled={loading || disabled}
      title={title}
      label={label}
      tooltip={title}
      tooltipOptions={{ position: 'bottom' }}
      data-pr-at={dataPrAt}
    >
      {loading && <LoadingIcon className="h-4 w-4 fill-white animate-spin" />}
      {!loading && <SaveIcon className="fill-white cursor-pointer" />}
    </Button >
  )
}

export default SaveButton

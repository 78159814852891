import { Button } from 'primereact/button'
import { Panel } from 'primereact/panel'
import { classNames } from 'primereact/utils'
import { FC, useEffect, useRef, useState } from 'react'
import { toast } from 'react-hot-toast'
import { useNavigate, useParams } from 'react-router'
import { useRecoilState, useRecoilValue } from 'recoil'
import { useGetProject, useUpdateProject } from '../api/project-API'
import {
  useCreateRun,
  useGetSubmissions
} from '../api/submission-API'
import { CollapseIcon, ExpandIcon, PlusIcon } from '../components/Icons'
import ProjectForm from '../components/project/ProjectForm'
import SubmissionList from '../components/submission/SubmissionList'
import SubmissionModal from '../components/submission/SubmissionModal'
import { protoService } from '../proto/ProtoService'
import {
  Geometry,
  LazyTableState,
  Project,
  ProjectConfig,
  ProjectStatus,
  RunConfigProto,
  RunStatus,
  User,
  WorkflowSubmission,
  WorkflowSubmissionTemplate
} from '../types/types'
import {
  cloneAttitudeSet,
  cloneDomainSet,
  cloneMethodologySet,
  cloneSimulationSet,
  cloneTyreSet,
  cloneUser,
  queryString2JsonString
} from '../types/util'
import Breadcrumbs, { Crumb } from '../components/Breadcrumbs'
import {
  useBreadcrumbNamesGet,
  useBreadcrumbRoutePathGet,
  useBreadcrumbTitlesGet,
  useBreadcrumbUrlsGet,
} from '../contexts/breadcrumbContexts/BreadcrumbContext'
import { createBreadcrumbs } from '../utils/breadcrumb_paths'
import {
  currentProjectState,
  currentVehicleState,
  engineerListState,
  workflowTemplateListState
} from '../utils/state-atoms'
import Breadcrumb, { BreadcrumbItem } from '../components/Breadcrumb'
import { useGetVehicle } from '../api/vehicle-API'
import { DataTableFilterMeta, DataTablePageEvent } from 'primereact/datatable'
import routes from '../routing/routes'
import useSetBreadcrumbs from '../hooks/useSetBreadcrumbs'
import { simpleRunProjection } from '../utils/constants'

const defaultFilters: DataTableFilterMeta = {
  projectId: { value: undefined, matchMode: 'equals' },
}

const projection = encodeURIComponent(
  JSON.stringify({
    _id: 1,
    name: 1,
    shortName: 1,
    description: 1,
    overallStatus: 1,
    ['workflowRunGroups.systemRunId']: 1,
    ['runConfig.description']: 1,
    ['runConfig.referenceRunName']: 1,
    ['runConfig.baselineRunName']: 1,
  })
)

const EditProject: FC = () => {
  const params = useParams()
  const [breadcrumItems, setBreadcrumItems] = useState<BreadcrumbItem[]>([])
  const navigate = useNavigate()
  const engineers = useRecoilValue(engineerListState)
  const templates = useRecoilValue(workflowTemplateListState)
  const [showDetails, setShowDetails] = useState(false)
  const [showNewSubmission, setShowNewSubmission] = useState(false)
  const refDetails = useRef<Panel>(null)
  const [currentVehicle, setCurrentVehicle] = useRecoilState(currentVehicleState)
  const [submissions, setSubmissions] = useState<WorkflowSubmission[]>([])
  const [totalRecords, setTotalRecords] = useState<number>(10)
  const [{ data: vehicleData }, fetchVehicle] = useGetVehicle('')
  const [{ data: projData }, fetchProject] = useGetProject()
  const [currentProject, setCurrentProject] = useRecoilState(currentProjectState)
  const [{ loading: updatingProject, error: errorUpdatingProject }, updateProject] =
    useUpdateProject(params.id || '')
  const [{ loading: creatingSubmission }, createSubmission] = useCreateRun()
  const [latestRuns, setLatestRuns] = useState<WorkflowSubmission[]>([])
  const [{ data: submissionData, loading: laodingRun }, fetchSubmissions] = useGetSubmissions()
  const [{ data: latestRunData, loading: loadingLatestRun }, fetchLatestRuns] = useGetSubmissions()

  const [lazyState, setLazyState] = useState<LazyTableState>({
    first: 0,
    rows: 0,
    page: 1,
    sortField: 'shortName',
    sortOrder: -1,
    filters: {
      ...defaultFilters,
    },
    sumlvl: 'vehicleStatesList:0,geometryList:0,tyreSetList:0,cfdSequenceList:0,method:0'
  })

  useEffect(() => {
    let query
    if (lazyState.filters.projectId['value']) {
      const q = 'projectId=' + lazyState.filters.projectId['value']
      query = query ? `${query}&${q}` : q
    }

    if (!query) {
      return
    }

    query = queryString2JsonString(query)
    query = query ? `query=${query}&limit=${lazyState.rows}` : `limit=${lazyState.rows}`
    query = `${query}&proj=${projection}&skip=${lazyState.first}&countTotal=true`
    if (lazyState.sortField) {
      const sort = {
        [lazyState.sortField]: lazyState.sortOrder
      }
      query = `${query}&sort=${JSON.stringify(sort)}`
    }
    fetchSubmissions(query).catch((err) => {
      console.error(err)
      toast.error('Error loading runs.')
    })
  }, [lazyState])


  const handleDataPage = (event: DataTablePageEvent) => {
    setLazyState({
      ...lazyState,
      first: 0,
      page: event.page as number,
      rows: 0
    })
  }

  const saveProject = async (projectFormData: Project) => {
    const newProj = new Project()
    newProj._id = currentProject?._id as string
    newProj.status = projectFormData.status
    newProj.isBaseline = projectFormData.isBaseline
    newProj.description = projectFormData.description
    newProj.outputParams = projectFormData.outputParams
    newProj.externalDocumentUrl = projectFormData.externalDocumentUrl
    newProj.area = projectFormData.area
    newProj.vehicleId = projectFormData.vehicleId
    newProj.engineer = cloneUser(projectFormData.engineer)
    if (projectFormData.methodSet) {
      newProj.methodSet = cloneMethodologySet(projectFormData.methodSet)
    }
    if (projectFormData.attitudeSet) {
      newProj.attitudeSet = cloneAttitudeSet(projectFormData.attitudeSet)
    }
    if (projectFormData.domainSet) {
      newProj.domainSet = cloneDomainSet(projectFormData.domainSet)
    }
    if (projectFormData.tyreSet) {
      newProj.tyreSet = cloneTyreSet(projectFormData.tyreSet)
    }
    if (projectFormData.simulationSet) {
      newProj.simulationSet = cloneSimulationSet(projectFormData.simulationSet)
    }
    newProj.referenceRun = projectFormData.referenceRun
    newProj.referenceRunName = projectFormData.referenceRunName
    newProj.baselineRunName = projectFormData.baselineRunName
    newProj.baselineRunId = projectFormData.baselineRunId
    newProj.baselineSubmissionTemplate = projectFormData.baselineSubmissionTemplate
    if (projectFormData.projectConfig) {
      newProj.projectConfig = new ProjectConfig(projectFormData.projectConfig)
    }
    if (projectFormData.baselineGeometry) {
      newProj.baselineGeometry = new Geometry(projectFormData.baselineGeometry)
    }

    if (projectFormData.contributorIds) {
      newProj.contributors = projectFormData.contributorIds.map((contributorId) => {
        const contributor = engineers.find((eng) => eng._id === contributorId)
        return cloneUser(contributor as User)
      })
    }

    try {
      const dataToPost = protoService.encodePayload(newProj, 'project')
      const res = await updateProject({
        data: dataToPost
      })

      if (res.data.protoBytesList && res.data.protoBytesList.length > 0) {
        const tempProj = protoService.deSerialize(res.data.protoBytesList[0], 'project')
        tempProj.name = currentProject?.name as string
        tempProj.shortName = currentProject?.shortName as string
        setCurrentProject(tempProj)
      }

      toast.success('Project updated.')
    } catch (err) {
      console.error(err)
      toast.error('Error saving project.')
    }
  }

  const reopenProject = async () => {
    const newProj = new Project()
    newProj.status = ProjectStatus.ACTIVE
    newProj._id = currentProject?._id as string

    try {
      const dataToPost = protoService.encodePayload(newProj, 'project')
      await updateProject({
        data: dataToPost
      })

      toast.success('Project updated.')
      navigate(-1)
    } catch (err) {
      console.error(err)
      toast.error('Error saving project.')
    }
  }

  const handleCreateNewSubmission = () => {
    setShowNewSubmission(true)
  }

  useEffect(() => {
    fetchProject(params.id).catch((err) => {
      console.error(err)
      toast.error('Error loading project.')
    })

    fetchLatestRuns(`limit=10&proj=${simpleRunProjection}`)
  }, [])

  useEffect(() => {
    if (latestRunData) {
      if (latestRunData.protoBytesList.length) {
        setLatestRuns(
          latestRunData.protoBytesList.map((item: string) => {
            const submission = protoService.deSerialize(item, 'workflowsubmission')
            return submission
          })
        )
      } else {
        setLatestRuns([{
          _id: '',
          name: ' '
          // name: 'No runs found'
        } as WorkflowSubmission])
      }
    } else {
      setLatestRuns([{
        _id: '',
        name: ' '
        // name: 'No runs found'
      } as WorkflowSubmission])
    }
  }, [latestRunData])

  useEffect(() => {
    if (projData && projData.protoBytesList.length) {
      const proj = protoService.deSerialize(projData.protoBytesList[0], 'project')
      console.log(proj)

      if (templates.length) {
        const template = templates.find((item) => item._id === proj.baselineSubmissionTemplate)
        if (template) {
          proj.submissionTemplateObj = template as WorkflowSubmissionTemplate
          console.log(proj)
        }

        if (currentVehicle) {
          setBreadcrumItems([
            {
              title: 'CAR',
              name: currentVehicle.name,
              link: `/projects?vehicleId=${currentVehicle._id}`
            },
            {
              title: 'PROJECT',
              name: proj?.name || ''
            }
          ])
        } else {
          console.log("proj.vehicleId:", proj.vehicleId)
          fetchVehicle({
            url: '/Vehicles/' + proj.vehicleId
          })
        }
      }

      setCurrentProject(proj)
      setLazyState({
        ...lazyState,
        filters: {
          projectId: {
            value: proj._id,
            matchMode: 'equals'
          }
        }
      })
    }
  }, [projData])

  useEffect(() => {
    if (vehicleData && vehicleData.protoBytesList.length) {
      const vehicle = protoService.deSerialize(vehicleData.protoBytesList[0], 'vehicle')
      setCurrentVehicle(vehicle)
      setBreadcrumItems([
        {
          title: 'CAR',
          name: `C${vehicle.vehicleId}`,
          link: `/projects?vehicleId=${vehicle._id}`
        },
        {
          title: 'PROJECT',
          name: currentProject?.name || ''
        }
      ])
    }
  }, [vehicleData])

  useEffect(() => {
    if (submissionData) {
      const runList = submissionData.protoBytesList.map((item: string) => {
        const submission = protoService.deSerialize(item, 'workflowsubmission')
        return submission
      })
      setSubmissions(runList)
      console.log('RunList', runList)

      if (submissionData.resultString) {
        const result = JSON.parse(submissionData.resultString)
        setTotalRecords(result.totalCount)
      }
    }
  }, [submissionData])

  const saveSubmission = async (runConfig: RunConfigProto, submission: WorkflowSubmission) => {
    console.log('---->', runConfig, submission)

    //save submission
    submission.overallStatus = RunStatus.CREATED
    let newWorkflowSubmission: WorkflowSubmission
    try {
      // prepare workflow submission
      submission.runConfig = runConfig
      const dataToPost = protoService.encodePayload(submission, 'workflowsubmission')
      const submissionRes = await createSubmission({ data: dataToPost })
      newWorkflowSubmission = protoService.deSerialize(
        submissionRes.data.protoBytesList[0],
        'workflowsubmission'
      )
      console.log(newWorkflowSubmission)
      toast.success('Workflow submission saved.')
      setShowNewSubmission(false)
      navigate('/submissions/' + newWorkflowSubmission.name)
    } catch (err) {
      console.error(err)
      toast.error('Error saving workflow submission.')
      return
    }
  }

  /* Breadcrumb init and set */
  const getBreadcrumbRoutePath = useBreadcrumbRoutePathGet()
  const ANCESTOR_API_ROUTES: string[] = getBreadcrumbRoutePath(routes.EditProject.absolutePath)

  const { id } = useParams()

  useSetBreadcrumbs(routes.EditProject.absolutePath, id)

  // setting up breadcrumbs from Breadcrumb Tree for display
  const getBreadcrumbNames = useBreadcrumbNamesGet()
  const names: string[] = getBreadcrumbNames(ANCESTOR_API_ROUTES)
  const getBreadcrumbTitles = useBreadcrumbTitlesGet()
  const titles: string[] = getBreadcrumbTitles(ANCESTOR_API_ROUTES)
  const getBreadcrumbUrls = useBreadcrumbUrlsGet()
  const urls: string[] = getBreadcrumbUrls(ANCESTOR_API_ROUTES)

  const breadcrumbs: Crumb[] = createBreadcrumbs(titles, names, urls)

  return (
    <>
      <div className="relative w-full flex flex-col items-stretch pb-10">
        <div className="flex items-center justify-between py-6">
          <Breadcrumbs ancestorBreadcrumbs={breadcrumbs} />
          <div className="flex items-center justify-between">
            <Button
              onClick={handleCreateNewSubmission}
              severity="secondary"
              className="icon-button"
              tooltip="Create New Run"
              tooltipOptions={{ position: 'bottom' }}
              data-pr-at={!showDetails ? 'center+50 bottom' : 'center+37 bottom'}
            >
              <PlusIcon className="fill-white" />
            </Button>
          </div>
        </div>
        <Panel
          ref={refDetails}
          toggleable
          collapsed={!showDetails}
          header={
            <div className="flex items-center justify-between space-x-1 ">
              <Button
                className="icon-button bg-transparent "
                link
                onClick={(evt) => {
                  refDetails.current?.toggle(evt)
                }}>
                {!showDetails && <ExpandIcon className="fill-dark-blue dark:fill-neutral-10 h-6 w-6" />}
                {showDetails && <CollapseIcon className="fill-dark-blue dark:fill-neutral-10 h-6 w-6" />}
              </Button>
              <span className="text-neutral-90 dark:text-light-blue text-lg">Project Details</span>
            </div>
          }
          onExpand={(e) => setShowDetails(true)}
          onCollapse={(e) => setShowDetails(false)}
          onAnimationEnd={(e) => {
            console.log(e)
          }}
          pt={{
            root: {
              className: 'rounded-lg shadow-md dark:border-neutral-90'
            },
            toggler: {
              className: 'hidden'
            },
            header: {
              className: classNames('bg-alt-gray dark:bg-neutral-80 dark:border-neutral-90 !p-2', {
                'rounded-b-none': showDetails,
                'rounded-b-md': !showDetails
              })
            },
            content: { className: 'p-4 dark:bg-neutral-80 dark:border-neutral-90' }
          }}>
          {currentProject && showDetails && (
            <ProjectForm
              saving={updatingProject}
              onSave={saveProject}
              onReopen={reopenProject}
              initProject={currentProject as Project}
              mode={
                currentProject && currentProject.status === ProjectStatus.ARCHIVED ? 'view' : 'edit'
              }
            />
          )}
        </Panel>
        <div className='text-gm-primary-90 text-xl mt-8 dark:text-light-blue'>
          Run List
        </div>
        <div>
          <SubmissionList
            submissions={submissions}
            loading={laodingRun}
            onPageChange={handleDataPage} />
        </div>
      </div>
      {showNewSubmission && (
        <SubmissionModal
          mode="new"
          submitting={false}
          latestRuns={latestRuns}
          project={currentProject as Project}
          onCancel={() => setShowNewSubmission(false)}
          onConfirm={(runconfig, submission) => {
            console.log(runconfig, submission)
            saveSubmission(runconfig, submission)
          }}
        />
      )}
    </>
  )
}

export default EditProject

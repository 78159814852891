import {
  AnsaMethod,
  AttitudeSet,
  CFDFile,
  CFDPartsList,
  DomainSet,
  GMRef,
  Geometry,
  Part,
  PlatformBatch,
  PlatformCopyConfig,
  PlatformRemoteCommand,
  ProjectConfig,
  RaceTeamRole,
  RescaleCoreSummary,
  RescaleJobVariable,
  RescaleResourceFilter,
  RunConfig,
  RunConfigCase,
  SimulationSet,
  StarCCMMethod,
  Trajectory,
  TyreLoadedRadius,
  TyreSet,
  TyreState,
  TyreStateTransform,
  WingState,
  DomainState,
  SimulationState,
  AttitudeState,
  CodeDir,
  AnsaDir,
  StarCCMDir,
  JobFile,
  MethodologySet,
  PlatformJob,
  RescaleAnalysisVariable,
  RescaleFlag,
  RescaleHardwareVariable,
  RescaleJob,
  RescaleJobAnalysis,
  RescaleToken,
  User,
  Workflow,
  WorkflowTask,
  WorkflowTaskStatus,
  WorkflowSubmissionTemplate,
  CmdDir,
  PeriscopeDir,
  CarConfig,
  SteeringTable
} from '@aero-platform/shared'

export const cloneUser = (user: User): User => {
  const newUser = new User()
  newUser.raceTeamId = user.raceTeamId
  newUser.userId = user.userId
  newUser._id = user._id
  newUser.timeCreated = user.timeCreated
  newUser.timeUpdated = user.timeUpdated
  newUser.createByUser = user.createByUser
  newUser.updateByUser = user.updateByUser
  newUser.firstName = user.firstName
  newUser.lastName = user.lastName
  newUser.email = user.email
  if (user.role) {
    newUser.role = cloneRaceTeamRole(user.role)
  }
  newUser.cloudProviderLDAPRef = user.cloudProviderLDAPRef
  if (user.rescaleTokens) {
    newUser.rescaleTokens = cloneRescaleTokenArray(user.rescaleTokens)
  }

  return newUser
}

const cloneRaceTeamRole = (role: RaceTeamRole) => {
  const newRole = new RaceTeamRole()
  newRole.raceTeamId = role.raceTeamId
  newRole.roleId = role.roleId
  newRole._id = role._id
  newRole.timeCreated = role.timeCreated
  newRole.timeUpdated = role.timeUpdated
  newRole.createByUser = role.createByUser
  newRole.updateByUser = role.updateByUser
  newRole.name = role.name
  newRole.description = role.description

  return newRole
}

const cloneRescaleTokenArray = (array: RescaleToken[]) => {
  return array.map((item) => {
    return new RescaleToken(item)
  })
}

export const cloneWingState = (wingState: WingState) => {
  const newWingState = new WingState()
  newWingState.drs = wingState.drs
  newWingState.fwState = wingState.fwState

  return newWingState
}

export const cloneWorkflowSubmissionTemplate = (template: WorkflowSubmissionTemplate) => {
  const newTemplate = new WorkflowSubmissionTemplate()
  newTemplate.raceTeamId = template.raceTeamId
  newTemplate._id = template._id.toString()
  newTemplate.timeCreated = template.timeCreated
  newTemplate.timeUpdated = template.timeUpdated
  newTemplate.createByUser = template.createByUser
  newTemplate.updateByUser = template.updateByUser
  newTemplate.name = template.name
  newTemplate.description = template.description

  if (template.templateWorkflows && template.templateWorkflows.length) {
    newTemplate.templateWorkflows = template.templateWorkflows.map((item) => {
      return cloneWorkflow(item)
    })
  }

  return newTemplate
}

export const cloneTyreState = (tyreState: TyreState) => {
  const newTyreState = new TyreState()
  newTyreState.raceTeamId = tyreState.raceTeamId
  newTyreState.tyreStateId = tyreState.tyreStateId
  if (tyreState._id) {
    newTyreState._id = tyreState._id.toString()
  }
  newTyreState.timeCreated = tyreState.timeCreated
  newTyreState.timeUpdated = tyreState.timeUpdated
  newTyreState.createByUser = tyreState.createByUser
  newTyreState.updateByUser = tyreState.updateByUser
  newTyreState.cadDir = tyreState.cadDir
  newTyreState.name = tyreState.name
  newTyreState.description = tyreState.description
  if (tyreState.loadedRadius) {
    newTyreState.loadedRadius = cloneTyreLoadedRadius(tyreState.loadedRadius)
  }
  if (tyreState.stateTransform) {
    newTyreState.stateTransform = cloneTyreStateTransform(tyreState.stateTransform)
  }
  if (tyreState.cadFileRef) {
    newTyreState.cadFileRef = tyreState.cadFileRef.map((item) => {
      return cloneCFDFile(item)
    })
  }
  return newTyreState
}

export const cloneTyreLoadedRadius = (tyreLoadedRadius: TyreLoadedRadius) => {
  const newTyreLoadedRadius = new TyreLoadedRadius()
  newTyreLoadedRadius.LS_FRONT = tyreLoadedRadius.LS_FRONT
  newTyreLoadedRadius.LS_REAR = tyreLoadedRadius.LS_REAR
  newTyreLoadedRadius.RS_FRONT = tyreLoadedRadius.RS_FRONT
  newTyreLoadedRadius.RS_REAR = tyreLoadedRadius.RS_REAR

  return newTyreLoadedRadius
}

export const cloneTyreStateTransform = (tyreStateTransform: TyreStateTransform) => {
  const newTyreStateTransform = new TyreStateTransform()
  newTyreStateTransform.FRH = tyreStateTransform.FRH
  newTyreStateTransform.RRH = tyreStateTransform.RRH
  newTyreStateTransform.ROLL = tyreStateTransform.ROLL
  newTyreStateTransform.STEER = tyreStateTransform.STEER

  return newTyreStateTransform
}

export const cloneTyreSet = (tyreSet: TyreSet) => {
  const newTyreSet = new TyreSet()

  newTyreSet.raceTeamId = tyreSet.raceTeamId
  newTyreSet.tyreId = tyreSet.tyreId
  newTyreSet.name = tyreSet.name
  if (tyreSet._id) {
    newTyreSet._id = tyreSet._id.toString()
  }
  newTyreSet.timeCreated = tyreSet.timeCreated
  newTyreSet.timeUpdated = tyreSet.timeUpdated
  newTyreSet.createByUser = tyreSet.createByUser
  newTyreSet.updateByUser = tyreSet.updateByUser
  newTyreSet.description = tyreSet.description
  if (tyreSet.statesJSON) {
    newTyreSet.statesJSON = tyreSet.statesJSON
  }
  if (tyreSet.tyreStates) {
    newTyreSet.tyreStates = tyreSet.tyreStates.map((item) => {
      return cloneTyreState(item)
    })
  }

  return newTyreSet
}

export const cloneMethodologySet = (methodologySet: MethodologySet) => {
  const newMethodologySet = new MethodologySet()
  newMethodologySet.raceTeamId = methodologySet.raceTeamId
  newMethodologySet._id = methodologySet._id.toString()
  newMethodologySet.timeCreated = methodologySet.timeCreated
  newMethodologySet.timeUpdated = methodologySet.timeUpdated
  newMethodologySet.createByUser = methodologySet.createByUser
  newMethodologySet.updateByUser = methodologySet.updateByUser
  newMethodologySet.name = methodologySet.name
  newMethodologySet.description = methodologySet.description
  newMethodologySet.methodId = methodologySet.methodId
  newMethodologySet.version = methodologySet.version
  newMethodologySet.archive = methodologySet.archive
  if (methodologySet.ansa) {
    newMethodologySet.ansa = cloneAnsaDir(methodologySet.ansa)
  }
  if (methodologySet.starccm) {
    newMethodologySet.starccm = cloneStarCCMDir(methodologySet.starccm)
  }
  if (methodologySet.periscope) {
    newMethodologySet.periscope = clonePeriscopeDir(methodologySet.periscope)
  }

  return newMethodologySet
}
export const cloneSterringTable = (steeringTable: SteeringTable) => {
  const newSteeringTable = new SteeringTable()
  newSteeringTable.name = steeringTable.name
  if (steeringTable.fileRef) {
    newSteeringTable.fileRef = cloneCFDFile(steeringTable.fileRef)
  }

  return newSteeringTable
}

export const cloneCarConfig = (carConfig: CarConfig) => {
  const newCarConfig = new CarConfig()
  newCarConfig.name = carConfig.name
  if (carConfig.cgCoord) {
    newCarConfig.cgCoord = carConfig.cgCoord.map((item) => {
      return item
    })
  }
  if (carConfig.slipCoord) {
    newCarConfig.slipCoord = carConfig.slipCoord.map((item) => {
      return item
    })
  }
  if (carConfig.fileRef) {
    newCarConfig.fileRef = cloneCFDFile(carConfig.fileRef)
  }

  return newCarConfig
}

export const cloneAnsaDir = (ansaDir: AnsaDir) => {
  const newAnsaDir = new AnsaDir()
  newAnsaDir.ansaVersionRescale = ansaDir.ansaVersionRescale
  newAnsaDir.codeDir = ansaDir.codeDir
  if (ansaDir.meshTemplate) {
    newAnsaDir.meshTemplate = cloneCFDFile(ansaDir.meshTemplate)
  }
  if (ansaDir.steeringTable) {
    newAnsaDir.steeringTable = cloneSterringTable(ansaDir.steeringTable)
  }
  if (ansaDir.carConfig) {
    newAnsaDir.carConfig = cloneCarConfig(ansaDir.carConfig)
  }
  if (ansaDir.code) {
    newAnsaDir.code = cloneCodeDir(ansaDir.code)
  }
  if (ansaDir.template) {
    newAnsaDir.template = cloneCFDFile(ansaDir.template)
  }
  if (ansaDir.cmd) {
    newAnsaDir.cmd = cloneCmdDir(ansaDir.cmd)
  }

  return newAnsaDir
}


export const cloneCmdDir = (cmdDir: CmdDir) => {
  const newCmdDir = new CmdDir()
  newCmdDir.path = cmdDir.path

  return newCmdDir
}

export const clonePeriscopeDir = (periscopeDir: PeriscopeDir) => {
  const newPeriscopeDir = new PeriscopeDir()
  if (periscopeDir.full) {
    newPeriscopeDir.full = cloneCFDFile(periscopeDir.full)
  }
  if (periscopeDir.half) {
    newPeriscopeDir.half = cloneCFDFile(periscopeDir.half)
  }
  if (periscopeDir.cmd) {
    newPeriscopeDir.cmd = cloneCmdDir(periscopeDir.cmd)
  }

  return newPeriscopeDir
}

export const cloneCodeDir = (codeDir: CodeDir) => {
  const newCodeDir = new CodeDir()
  newCodeDir.path = codeDir.path

  return newCodeDir
}

export const cloneStarCCMDir = (starCCMDir: StarCCMDir) => {
  const newStarCCMDir = new StarCCMDir()
  newStarCCMDir.codeDir = starCCMDir.codeDir
  newStarCCMDir.codeFileName = starCCMDir.codeFileName
  newStarCCMDir.starVersionRescale = starCCMDir.starVersionRescale
  newStarCCMDir.starVersionServer = starCCMDir.starVersionServer
  if (starCCMDir.codeSetupPath) {
    newStarCCMDir.codeSetupPath = cloneCFDFile(starCCMDir.codeSetupPath)
  }
  if (starCCMDir.codeSolvePath) {
    newStarCCMDir.codeSolvePath = cloneCFDFile(starCCMDir.codeSolvePath)
  }
  if (starCCMDir.template) {
    newStarCCMDir.template = cloneCFDFile(starCCMDir.template)
  }
  if (starCCMDir.configJson) {
    newStarCCMDir.configJson = cloneCFDFile(starCCMDir.configJson)
  }
  if (starCCMDir.config) {
    newStarCCMDir.config = cloneCFDFile(starCCMDir.config)
  }
  if (starCCMDir.code) {
    newStarCCMDir.code = cloneCodeDir(starCCMDir.code)
  }

  return newStarCCMDir
}


export const cloneStarCCMMethod = (method: StarCCMMethod) => {
  const newMethod = new StarCCMMethod()
  newMethod.codeDir = method.codeDir
  newMethod.starVersionRescale = method.starVersionRescale
  newMethod.starVersionServer = method.starVersionServer
  if (method.codeSetupPath) {
    newMethod.codeSetupPath = cloneCFDFile(method.codeSetupPath)
  }
  if (method.codeSolvePath) {
    newMethod.codeSolvePath = cloneCFDFile(method.codeSolvePath)
  }
  if (method.template) {
    newMethod.template = cloneCFDFile(method.template)
  }

  return newMethod
}

export const cloneAnsaMethod = (method: AnsaMethod) => {
  const newMethod = new AnsaMethod()
  if (method.meshTemplate) {
    newMethod.meshTemplate = cloneCFDFile(method.meshTemplate)
  }
  newMethod.ansaVersionRescale = method.ansaVersionRescale
  if (method.codeDesignScript) {
    newMethod.codeDesignScript = cloneCFDFile(method.codeDesignScript)
  }
  if (method.codeKinScript) {
    newMethod.codeKinScript = cloneCFDFile(method.codeKinScript)
  }
  newMethod.codeDir = method.codeDir

  return newMethod
}

export const cloneRunConfigCase = (runConfigCase: RunConfigCase) => {
  const newRunConfigCase = new RunConfigCase()
  newRunConfigCase.name = runConfigCase.name
  newRunConfigCase.key = runConfigCase.key
  if (runConfigCase.selectedAttitudeState) {
    newRunConfigCase.selectedAttitudeState = new AttitudeState(runConfigCase.selectedAttitudeState)
  }
  if (runConfigCase.selectedDomainState) {
    newRunConfigCase.selectedDomainState = cloneDomainState(runConfigCase.selectedDomainState)
  }
  if (runConfigCase.selectedSimulationState) {
    newRunConfigCase.selectedSimulationState = cloneSimulationState(
      runConfigCase.selectedSimulationState
    )
  }
  if (runConfigCase.selectedTyreState) {
    newRunConfigCase.selectedTyreState = cloneTyreState(runConfigCase.selectedTyreState)
  }
  if (runConfigCase.selectedWingState) {
    newRunConfigCase.selectedWingState = cloneWingState(runConfigCase.selectedWingState)
  }
  newRunConfigCase.useDeformableTires = runConfigCase.useDeformableTires

  return newRunConfigCase
}

export const cloneRunConfig = (config: RunConfig) => {
  const newConfig = new RunConfig()
  newConfig.raceTeamId = config.raceTeamId
  newConfig.runId = config.runId
  newConfig._id = config._id
  newConfig.timeCreated = config.timeCreated
  newConfig.timeUpdated = config.timeUpdated
  newConfig.createByUser = config.createByUser
  newConfig.updateByUser = config.updateByUser
  newConfig.expandForVehicleStates = config.expandForVehicleStates
  newConfig.name = config.name
  newConfig.description = config.description
  newConfig.stats = config.stats
  newConfig.RATGstatus = config.RATGstatus
  newConfig.expandForVehicleStates = config.expandForVehicleStates
  newConfig.MAUhUsage = config.MAUhUsage
  if (config.selectedMethodSet) {
    newConfig.selectedMethodSet = cloneMethodologySet(config.selectedMethodSet)
  }
  if (config.selectedMasterGeometry) {
    newConfig.selectedMasterGeometry = new Geometry(config.selectedMasterGeometry)
  }
  if (config.projectConfig) {
    newConfig.projectConfig = new ProjectConfig(config.projectConfig)
  }
  if (config.workflowSubmissionTemplate) {
    newConfig.workflowSubmissionTemplate = cloneWorkflowSubmissionTemplate(config.workflowSubmissionTemplate)
  }

  newConfig.submittedByUser = config.submittedByUser
  newConfig.baselineRunName = config.baselineRunName
  newConfig.referenceRunName = config.referenceRunName
  newConfig.referenceRunId = config.referenceRunId
  newConfig.baselineRunId = config.baselineRunId
  if (config.selectedTrajectory) {
    newConfig.selectedTrajectory = new Trajectory(config.selectedTrajectory)
  }
  if (config.selectedAttitudeSet) {
    newConfig.selectedAttitudeSet = new AttitudeSet(config.selectedAttitudeSet)
  }
  if (config.selectedDomainSet) {
    newConfig.selectedDomainSet = new DomainSet(config.selectedDomainSet)
  }
  if (config.selectedSimulationSet) {
    newConfig.selectedSimulationSet = new SimulationSet(config.selectedSimulationSet)
  }
  config.selectedCases.forEach((item) => {
    newConfig.selectedCases.push(cloneRunConfigCase(item))
  })

  return newConfig
}

export const cloneWorkflow = (workflow: Workflow) => {
  const newWorkflow = new Workflow()
  newWorkflow.raceTeamId = workflow.raceTeamId
  newWorkflow._id = workflow._id
  newWorkflow.timeCreated = workflow.timeCreated
  newWorkflow.timeUpdated = workflow.timeUpdated
  newWorkflow.createByUser = workflow.createByUser
  newWorkflow.updateByUser = workflow.updateByUser
  newWorkflow.workflowId = workflow.workflowId
  newWorkflow.name = workflow.name
  newWorkflow.description = workflow.description
  workflow.runConfigCases && workflow.runConfigCases.forEach((item) => {
    newWorkflow.runConfigCases.push(new RunConfigCase(item))
  })
  newWorkflow.systemRunId = workflow.systemRunId
  newWorkflow.enabled = workflow.enabled
  newWorkflow.dependent = workflow.dependent
  newWorkflow.overallStatus = workflow.overallStatus
  newWorkflow.baselineRunName = workflow.baselineRunName
  newWorkflow.createdFromTemplate = workflow.createdFromTemplate
  newWorkflow.startTime = workflow.startTime
  newWorkflow.endTime = workflow.endTime

  if (workflow.workflowTasks) {
    newWorkflow.workflowTasks = workflow.workflowTasks.map((item) => {
      return cloneWorkflowTask(item)
    })
  }
  if (workflow.methodology) {
    newWorkflow.methodology = workflow.methodology
  }
  newWorkflow.configJSON = workflow.configJSON

  return newWorkflow
}

export const clonePlatformJob = (job: PlatformJob) => {
  const newJob = new PlatformJob()
  newJob.name = job.name
  newJob.description = job.description
  newJob.type = job.type
  if (job.inputFiles) {
    newJob.inputFiles = job.inputFiles.map((item) => {
      return new JobFile(item)
    })
  }
  if (job.outputFiles) {
    newJob.outputFiles = job.outputFiles.map((item) => {
      return new JobFile(item)
    })
  }
  newJob.executeAPIEndpoint = job.executeAPIEndpoint
  newJob.httpMethod = job.httpMethod
  newJob.payload = job.payload
  newJob.active = job.active
  if (job.platformBatchCommand) {
    newJob.platformBatchCommand = clonePlatformBatchCommand(job.platformBatchCommand)
  }
  if (job.platformRemoteCommand) {
    newJob.platformRemoteCommand = clonePlatformRemoteCommand(job.platformRemoteCommand)
  }
  if (job.platformCopyConfig) {
    newJob.platformCopyConfig = clonePlatformCopyConfig(job.platformCopyConfig)
  }

  return newJob
}

export const clonePlatformCopyConfig = (cfg: PlatformCopyConfig) => {
  const newCfg = new PlatformCopyConfig()
  newCfg.sourceAccount = cfg.sourceAccount
  newCfg.sourceBlobAddr = cfg.sourceBlobAddr
  newCfg.sourceContainerName = cfg.sourceContainerName
  newCfg.destinationAccount = cfg.destinationAccount
  newCfg.destinationBlobAddr = cfg.destinationBlobAddr
  newCfg.destinationContainerName = cfg.destinationContainerName
  newCfg.sourceFileType = cfg.sourceFileType
  newCfg.destinationFileType = cfg.destinationFileType
  newCfg.rawFileAsString = cfg.rawFileAsString
  newCfg.includeFileExtension = cfg.includeFileExtension
  newCfg.removeF1PartDups = cfg.removeF1PartDups
  return newCfg
}

export const clonePlatformRemoteCommand = (command: PlatformRemoteCommand) => {
  const newCommand = new PlatformRemoteCommand()
  newCommand.localScriptCommand = command.localScriptCommand
  newCommand.localScriptPath = command.localScriptPath
  if (command.gmRef) {
    newCommand.gmRef = new GMRef(command.gmRef)
  }

  return newCommand
}

export const clonePlatformBatchCommand = (command: PlatformBatch) => {
  const newCommand = new PlatformBatch()
  newCommand.createTasksClassname = command.createTasksClassname
  if (command.batchTasks) {
    newCommand.batchTasks = command.batchTasks.map((item) => {
      return cloneWorkflowTask(item)
    })
  }

  return newCommand
}

export const cloneWorkflowTask = (task: WorkflowTask) => {
  const newTask = new WorkflowTask()
  newTask.raceTeamId = task.raceTeamId
  newTask._id = task._id
  newTask.timeCreated = task.timeCreated
  newTask.timeUpdated = task.timeUpdated
  newTask.createByUser = task.createByUser
  newTask.updateByUser = task.updateByUser
  newTask.name = task.name
  newTask.description = task.description
  if (task.runConfig) {
    newTask.runConfig = cloneRunConfig(task.runConfig)
  }
  if (task.status) {
    newTask.status = new WorkflowTaskStatus(task.status)
  }
  if (task.rescaleJob) {
    newTask.rescaleJob = cloneRescaleJob(task.rescaleJob)
  }
  if (task.platformJob) {
    newTask.platformJob = clonePlatformJob(task.platformJob)
  }
  newTask.configJSON = task.configJSON
  newTask.taskId = task.taskId
  newTask.warnOnException = task.warnOnException
  newTask.startTime = task.startTime
  newTask.endTime = task.endTime
  newTask.queueTime = task.queueTime
  newTask.notifyOnCompletion = task.notifyOnCompletion
  newTask.reportingCategory = task.reportingCategory
  newTask.priority = task.priority
  newTask.autoRetriesAllowed = task.autoRetriesAllowed
  newTask.gmRefString = task.gmRefString
  newTask.type = task.type

  return newTask
}

const cloneRescaleJobVariable = (variable: RescaleJobVariable) => {
  const newVariable = new RescaleJobVariable()
  newVariable.displayOrder = variable.displayOrder
  newVariable.name = variable.name
  if (variable.valueGeneratorSettings) {
    newVariable.valueGeneratorSettings = variable.valueGeneratorSettings.map((item) => {
      return item
    })
  }
  newVariable.valueGeneratorType = variable.valueGeneratorType
  newVariable.variableType = variable.variableType

  return newVariable
}

const cloneRescaleJobAnalysis = (analysis: RescaleJobAnalysis) => {
  const newAnalysis = new RescaleJobAnalysis()
  newAnalysis.analysis = new RescaleAnalysisVariable(analysis.analysis)
  newAnalysis.command = analysis.command
  if (analysis.envVars) {
    newAnalysis.envVars = analysis.envVars
  }
  if (analysis.flags) {
    newAnalysis.flags = new RescaleFlag(analysis.flags)
  }
  if (analysis.hardware) {
    newAnalysis.hardware = cloneRescaleHardwareVariable(analysis.hardware)
  }

  return newAnalysis
}

const cloneRescaleHardwareVariable = (variable: RescaleHardwareVariable) => {
  const newVariable = new RescaleHardwareVariable()
  if (variable.coreSummary) {
    newVariable.coreSummary = new RescaleCoreSummary(variable.coreSummary)
  }
  newVariable.coreType = variable.coreType
  newVariable.coresPerSlot = variable.coresPerSlot
  newVariable.slots = variable.slots
  newVariable.walltime = variable.walltime

  return newVariable
}

export const cloneRescaleJob = (job: RescaleJob) => {
  const newJob = new RescaleJob()
  newJob.name = job.name
  newJob.paramFile = job.paramFile
  newJob.caseFile = job.caseFile
  if (job.jobvariables) {
    newJob.jobvariables = job.jobvariables.map((item) => {
      return cloneRescaleJobVariable(item)
    })
  }
  if (job.jobanalyses) {
    newJob.jobanalyses = job.jobanalyses.map((item) => {
      return cloneRescaleJobAnalysis(item)
    })
  }
  if (job.resourcefilters) {
    newJob.resourcefilters = job.resourcefilters.map((item) => {
      return new RescaleResourceFilter(item)
    })
  }
  newJob.isTemplateDryRun = job.isTemplateDryRun
  newJob.includeNominalRun = job.includeNominalRun
  newJob.monteCarloIterations = job.monteCarloIterations
  newJob.billingPriorityValue = job.billingPriorityValue

  return newJob
}

export const clonePart = (part: Part) => {
  const newPart = new Part()
  newPart.raceTeamId = part.raceTeamId
  if (part._id) {
    newPart._id = part._id.toString()
  }
  newPart.timeCreated = part.timeCreated
  newPart.timeUpdated = part.timeUpdated
  newPart.createByUser = part.createByUser
  newPart.updateByUser = part.updateByUser
  newPart.partId = part.partId
  newPart.name = part.name
  newPart.type = part.type
  newPart.fromWorkflowSubmissionId = part.fromWorkflowSubmissionId

  return newPart
}
export const cloneCFDFile = (file: CFDFile) => {
  const newFile = new CFDFile()
  newFile.raceTeamId = file.raceTeamId
  if (file._id) {
    newFile._id = file._id.toString()
  }
  newFile.timeCreated = file.timeCreated
  newFile.timeUpdated = file.timeUpdated
  newFile.createByUser = file.createByUser
  newFile.updateByUser = file.updateByUser
  newFile.file = file.file
  newFile.path = file.path
  newFile.fileId = file.fileId
  newFile.name = file.name
  newFile.description = file.description
  newFile.archive = file.archive
  newFile.system = file.system
  newFile.version = file.version
  newFile.fileType = file.fileType
  newFile.rootType = file.rootType
  if (file.partRef) {
    newFile.partRef = clonePart(file.partRef)
  }

  return newFile
}

export const cloneCFDPartsList = (partsList: CFDPartsList) => {
  const newPartsList = new CFDPartsList()
  if (partsList._id) {
    newPartsList._id = partsList._id.toString()
  }
  newPartsList.raceTeamId = partsList.raceTeamId
  newPartsList.workflowSubmissionId = partsList.workflowSubmissionId
  newPartsList.baselineSubmissionId = partsList.baselineSubmissionId
  newPartsList.timeCreated = partsList.timeCreated
  newPartsList.timeUpdated = partsList.timeUpdated
  newPartsList.createByUser = partsList.createByUser
  newPartsList.updateByUser = partsList.updateByUser
  newPartsList.name = partsList.name
  newPartsList.description = partsList.description
  if (partsList.parts) {
    newPartsList.parts = partsList.parts.map((item) => {
      return cloneCFDFile(item)
    })
  }

  return newPartsList
}

export const queryString2JsonString = (query: string): string => {
  if (!query) {
    return ''
  }
  let params: Record<string, any> = new URLSearchParams(query)
  const result = {}
  for (const [key, value] of params.entries()) {
    // each 'entry' is a [key, value] tupple
    result[key] = value
  }
  return JSON.stringify(result)
}

export const cloneTrajectory = (trajectory: Trajectory) => {
  const newTrajectory = new Trajectory()
  newTrajectory.name = trajectory.name
  newTrajectory.description = trajectory.description
  if (trajectory.attitudeStates) {
    newTrajectory.attitudeStates = trajectory.attitudeStates.map((item) => item)
  }

  return newTrajectory
}

export const cloneAttitudeSet = (attitudeSet: AttitudeSet) => {
  const newAttitudeSet = new AttitudeSet()
  newAttitudeSet.raceTeamId = attitudeSet.raceTeamId
  newAttitudeSet._id = attitudeSet._id.toString()
  newAttitudeSet.name = attitudeSet.name
  newAttitudeSet.description = attitudeSet.description
  //// newAttitudeSet.documentMeta = attitudeSet.documentMeta
  if (attitudeSet.attitudeStates) {
    newAttitudeSet.attitudeStates = attitudeSet.attitudeStates.map((item) => {
      return new AttitudeState(item)
    })
  }
  if (attitudeSet.trajectories) {
    newAttitudeSet.trajectories = attitudeSet.trajectories.map((item) => {
      return cloneTrajectory(item)
    })
  }

  return newAttitudeSet
}

export const cloneDomainSet = (domainSet: DomainSet) => {
  const newDomainSet = new DomainSet()
  newDomainSet.raceTeamId = domainSet.raceTeamId
  newDomainSet._id = domainSet._id.toString()
  newDomainSet.name = domainSet.name
  newDomainSet.description = domainSet.description
  //newDomainSet.documentMeta = domainSet.documentMeta
  if (domainSet.domainStates) {
    newDomainSet.domainStates = domainSet.domainStates.map(
      (item) => cloneDomainState(item)
    )
  }

  return newDomainSet
}

export const cloneDomainState = (domainState: DomainState) => {
  const newDomainState = new DomainState()
  newDomainState.name = domainState.name
  newDomainState.description = domainState.description
  if (domainState.fileRef) {
    newDomainState.fileRef = cloneCFDFile(domainState.fileRef)
  }
  newDomainState.WTDomain = domainState.WTDomain
  if (domainState.MMSLocation) {
    newDomainState.MMSLocation = domainState.MMSLocation.map((item) => item)
  }

  return newDomainState
}

export const cloneSimulationState = (simulationState: SimulationState) => {
  const newSimulationState = new SimulationState()
  newSimulationState.name = simulationState.name
  newSimulationState.description = simulationState.description
  if (simulationState.type !== undefined) {
    // need to keep number value as number,  encoding will fail for string value
    // backends will convert to string
    newSimulationState.type = simulationState.type
    // SimulationStateType[simulationState.type.toString() as keyof SimulationStateType]
  }

  return newSimulationState
}

export const cloneSimulationSet = (simulationSet: SimulationSet) => {
  const newSimulationSet = new SimulationSet()
  newSimulationSet.raceTeamId = simulationSet.raceTeamId
  newSimulationSet._id = simulationSet._id.toString()
  newSimulationSet.name = simulationSet.name
  newSimulationSet.description = simulationSet.description
  //newSimulationSet.documentMeta = simulationSet.documentMeta
  if (simulationSet.simulationStates) {
    newSimulationSet.simulationStates = simulationSet.simulationStates.map((item) => {
      return cloneSimulationState(item)
    })
  }

  return newSimulationSet
}

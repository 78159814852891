import { Workflow } from "../../types/types"
import Droppable from "../dnd/Droppable"
import { EditIcon } from "../Icons"
import WorkflowTaskNode from "./WorkflowTaskNode"

type WorkflowNodeProps = {
  workflow: Workflow
  index: number
  editable?: boolean
  droppable?: boolean
  droppableId?: string
}
const WorkflowNode: React.FC<WorkflowNodeProps> = ({ workflow, index, editable, droppable, droppableId }) => {
  const renderTasks = () => {
    return (
      <ul className="space-y-1 flx-col items-end mt-1">
        {workflow.workflowTasks && workflow.workflowTasks.map((task, taskIndex) => {
          const unqiueTaskId = `tasknode-${workflow._id}-${index}-${task._id}-${taskIndex}`
          return (
            <li key={unqiueTaskId} className="flex justify-end">
              <WorkflowTaskNode
                key={task._id}
                task={task}
                droppable={droppable}
                droppableId={unqiueTaskId}
                draggable={droppable}
              />
            </li>
          )
        })}
      </ul>
    )
  }

  return (
    <>
      {droppable ? (
        <Droppable type="workflow" id={droppableId || ''} data={workflow}>
          <div className="node workflow-node flex">{workflow.name}</div>
          {renderTasks()}
        </Droppable>
      ) : (
        <>
          <div className="node workflow-node">{workflow.name}</div>
          {renderTasks()}
        </>
      )}
    </>
  )
}

export default WorkflowNode

import { FC, useEffect, useState } from 'react'
import { Workflow, WorkflowTask, WorkflowSubmissionTemplate } from '../../types/types'
import { DndContext, DragOverlay, PointerActivationConstraint, PointerSensor, rectIntersection, useSensor, useSensors } from '@dnd-kit/core'
import WorkflowNode from './WorkflowNode'
import WorkflowEditor from './WorkflowEditor'
import { DeleteIcon, EditIcon, LongRightIcon } from '../Icons'
import TaskPanel from './TaskPanel'
import { moveArrayItem } from '../../utils/intl'
import Draggable from '../dnd/Draggable'
import Droppable from '../dnd/Droppable'
import { Dropdown } from 'primereact/dropdown'
import { DropdownStyle } from '../../utils/styling-constants'
import { useRecoilValue } from 'recoil'
import { workflowTemplateListState } from '../../utils/state-atoms'
import { Dialog } from 'primereact/dialog'
import { Button } from 'primereact/button'
import toast from 'react-hot-toast'
import {
  useGetWorkflowTemplate,
} from '../../api/workflow-template-API'
import { protoService } from '../../proto/ProtoService'
import { useUpdateWorkflow } from '../../api/workflow-API'
import { ProgressSpinner } from 'primereact/progressspinner'


type WorkflowTemplateEditorProps = {
  loadingTemplate: boolean
  initWorkflowTemplate: WorkflowSubmissionTemplate
  onChange: (workflowTemplate: WorkflowSubmissionTemplate) => void
}

const WorkflowTemplateEditor: FC<WorkflowTemplateEditorProps> = ({
  loadingTemplate, initWorkflowTemplate, onChange
}) => {
  const globalWorkflowTemplateList = useRecoilValue(workflowTemplateListState)
  const [showTemplateDialog, setShowTemplateDialog] = useState(false)
  const [workflowEditingMode, setWorkflowEditingMode] = useState('new')
  const [showWorkflowEditor, setShowWorkflowEditor] = useState(false)
  const [showOverlay, setShowOverlay] = useState(false)
  const [activeDrag, setActiveDrag] = useState<WorkflowTask | Workflow | null>(null)
  const [currentWorkflow, setCurrentWorkflow] = useState<Workflow>({} as Workflow)
  const [selectedMasterWorkflow, setSelectedMasterWorkflow] = useState<WorkflowSubmissionTemplate | null>(null)
  const [currentWorkflowTemplate, setCurrentWorkflowTemplate] = useState<WorkflowSubmissionTemplate>(
    {} as WorkflowSubmissionTemplate
  )
  const pointerSensor = useSensor(PointerSensor, {
    activationConstraint: { distance: 10 } as PointerActivationConstraint
  })
  const sensors = useSensors(pointerSensor);

  const [{ }, fetchOneWorkflowTemplate] = useGetWorkflowTemplate()
  const [{ }, updateWorkflow] = useUpdateWorkflow()
  useEffect(() => {
    setCurrentWorkflowTemplate({ ...initWorkflowTemplate } as WorkflowSubmissionTemplate)
    setSelectedMasterWorkflow({ ...initWorkflowTemplate.masterTemplates[0] } as WorkflowSubmissionTemplate)
  }, [initWorkflowTemplate])

  const handleChosenMasterWorkflow = () => {
    setShowTemplateDialog(true)
  }

  const handleDragStart = ({ active }) => {
    console.log("Drag started: ", typeof active.data)

    if (active.data.current) {
      setShowOverlay(true);
      setActiveDrag({ ...active.data.current })
    }
  }

  const handleDragOver = ({ active }) => {
    // setActiveDrag(active);
    // setActiveDragId(active && active.id);
  }

  const handleDragCancell = ({ active }) => {
    setActiveDrag(null);
  }

  const handleDragEnd = ({ active, over }) => {
    console.log("Drag ended: ", active, over)
    if (!currentWorkflowTemplate || !over || !active || over.id === active.id) {
      setShowOverlay(true)
      setActiveDrag(null);
      return
    }

    if (active.data.current && (over.data.current || over.id === 'trash')) {
      const targetId = over.id
      const targetParts = targetId.split("-")
      setShowOverlay(false)
      let templateWorkflows
      console.log('dropped on trash target====>', targetId)

      if (targetId === 'trash') {  // trash can
        console.log('dropped on trash====>', active.id)
        if (active.id.startsWith('tasknode-')) {
          // remove the task from the workflow
          const targetParts = active.id.split("-")
          const [, targetWorkflowId, targetWorkflowIndex, targetTaskId, targetTaskIndex] = targetParts
          templateWorkflows = currentWorkflowTemplate.templateWorkflows.map((workflowItem, workflowIndex) => {
            if (targetWorkflowId === workflowItem._id && parseInt(targetWorkflowIndex) === workflowIndex) { // find the workflow to drop on
              const draggedTaskIndex = workflowItem.workflowTasks.findIndex((task, taskIndex) => {
                return targetTaskId === task._id && taskIndex === parseInt(targetTaskIndex)
              })
              if (draggedTaskIndex >= 0) {
                const newWorkflowTasks = workflowItem.workflowTasks.filter((task, taskIndex) => {
                  return taskIndex !== draggedTaskIndex
                })
                return {
                  ...workflowItem,
                  workflowTasks: newWorkflowTasks
                }
              } else {
                return workflowItem
              }
            } else {
              return workflowItem
            }
          })
        } else if (active.id.startsWith('workflow-')) {
          const [, targetWorkflowId, targetWorkflowIndex] = active.id.split("-")
          console.log(targetWorkflowId, targetWorkflowIndex)
          templateWorkflows = currentWorkflowTemplate.templateWorkflows.filter((workflowItem, workflowIndex) => {
            console.log(workflowItem._id, workflowIndex, targetWorkflowIndex)
            return targetWorkflowId !== workflowItem._id || parseInt(targetWorkflowIndex) !== workflowIndex
          })
        } else {
          setShowOverlay(true)
          setActiveDrag(null)
          return
        }
      } else if (targetId.startsWith('tasknode-') && active.id.startsWith('tasknode-')) {
        // move task with in workflow
        const [, targetWorkflowId, targetWorkflowIndex, , targetTaskIndex] = targetParts
        const [, activeWorkflowId, activeWorkflowIndex, , activeTaskIndex] = active.id.split("-")
        if (targetWorkflowId !== activeWorkflowId || targetWorkflowIndex !== activeWorkflowIndex) {
          // cross workflows, do nothing
          setShowOverlay(true)
          setActiveDrag(null)
          return
        }
        templateWorkflows = currentWorkflowTemplate.templateWorkflows.map((workflowItem, index) => {
          if (targetWorkflowId === workflowItem._id && parseInt(targetWorkflowIndex) === index) {
            // find the workflow to drop on
            return {
              ...workflowItem,
              workflowTasks: moveArrayItem(workflowItem.workflowTasks, parseInt(activeTaskIndex), parseInt(targetTaskIndex))
            }
          } else {
            // other flow, just return original workflow
            return workflowItem
          }
        })
      } else if (targetId.startsWith('tasknode-') && active.id.startsWith('task-')) { // drop on task
        const [, targetWorkflowId, targetWorkflowIndex, targetTaskId, targetTaskIndex] = targetParts
        templateWorkflows = currentWorkflowTemplate.templateWorkflows.map((workflowItem, workflowIndex) => {
          if (targetWorkflowId === workflowItem._id && parseInt(targetWorkflowIndex) === workflowIndex) { // find the workflow to drop on
            const droppedOnTaskIndex = workflowItem.workflowTasks.findIndex((task, taskIndex) => {
              return targetTaskId === task._id && taskIndex === parseInt(targetTaskIndex)
            })
            if (droppedOnTaskIndex >= 0) {
              const newWorkflowTasks = [...workflowItem.workflowTasks]
              newWorkflowTasks.splice(droppedOnTaskIndex, 0, active.data.current)
              return {
                ...workflowItem,
                workflowTasks: newWorkflowTasks
              }
            } else {
              return workflowItem
            }
          } else {
            return workflowItem
          }
        })
      } else if (targetId.startsWith('workflow-') && active.id.startsWith('task-')) {  //drop task on workflow
        const [, targetWorkflowId, targetWorkflowIndex] = targetParts
        templateWorkflows = currentWorkflowTemplate.templateWorkflows.map((workflowItem, index) => {
          if (targetWorkflowId === workflowItem._id && parseInt(targetWorkflowIndex) === index) {
            // find the workflow to drop on
            // then add the task to it
            return {
              ...workflowItem,
              workflowTasks: [
                ...workflowItem.workflowTasks,
                { ...active.data.current }
              ]
            }
          } else {
            return workflowItem
          }
        })
      } else if (targetId.startsWith('workflow-') && active.id.startsWith('workflow-')) {  //drop task on workflow
        const [, , targetWorkflowIndex] = targetParts
        const [, , activeWorkflowIndex] = active.id.split("-")
        if (parseInt(targetWorkflowIndex) !== parseInt(activeWorkflowIndex) + 1) {
          console.log('switch workflow', targetWorkflowIndex, activeWorkflowIndex)
          templateWorkflows = currentWorkflowTemplate.templateWorkflows
          templateWorkflows = moveArrayItem(templateWorkflows, parseInt(activeWorkflowIndex), parseInt(targetWorkflowIndex))
        }
      }

      if (templateWorkflows) {
        setShowOverlay(false)
        const newTemplate = {
          ...currentWorkflowTemplate,
          templateWorkflows
        } as WorkflowSubmissionTemplate
        setCurrentWorkflowTemplate(newTemplate)
        onChange && onChange(newTemplate)
      } else {
        setShowOverlay(true)
      }
    }
    setActiveDrag(null)
  }

  return (
    <div className="flex items-stretch col-span-1">
      <DndContext
        sensors={sensors}
        onDragStart={handleDragStart}
        onDragOver={handleDragOver}
        onDragEnd={handleDragEnd}
        onDragCancel={handleDragCancell}
        collisionDetection={rectIntersection}
      >
        {showOverlay &&
          <DragOverlay>
            {activeDrag &&
              <div className="drag-overlay z-[2000]">
                {(activeDrag.platformJob && activeDrag.platformJob.name) || (activeDrag.rescaleJob && activeDrag.rescaleJob.name) || activeDrag.name}
              </div>
            }
          </DragOverlay>}
        {/* Task editor begins*/}
        <div className="fixed left-2 top-[50%] p-4 bg-gray-400 rounded-lg w-[100px] h-[100px] flex items-center justify-center z-[100]">
          <Droppable id="trash" type="trash" data={null}>
            <div className="w-[100px] h-[100px] flex items-center justify-center bg-transparent">
              <DeleteIcon className="fill-neutral-80 h-6 w-6" />
            </div>
          </Droppable>
        </div>
        <div className="flex-grow bg-neutral-10 dark:bg-transparent dark:border dark:border-gray-600 rounded-lg p-4">
          <div className="w-full flex justify-center">
            {loadingTemplate && <ProgressSpinner
              strokeWidth="4"
              pt={{
                root: {
                  className: "w-8 h-8",
                },
                circle: {
                  className: "!stroke-gm-blue",
                },
              }}
            />
            }
          </div>
          {!loadingTemplate && currentWorkflowTemplate && currentWorkflowTemplate.masterTemplates &&
            <div className="flex w-full py-6">
              <div className="flex-grow grid grid-cols-1 justify-items-end items-start gap-3 auto-rows-max">
                <div className="node master-workflow-node">Master Workflow</div>
                {
                  currentWorkflowTemplate.templateWorkflows.map((workflowItem, workflowIndex) => {
                    const uniqueId = 'workflow-' + workflowItem._id + '-' + workflowIndex
                    return (
                      <Draggable id={uniqueId} data={workflowItem} key={uniqueId}>
                        <div
                          className="flex flex-col items-end mt-1 space-y-1 relative">
                          <WorkflowNode workflow={workflowItem} index={workflowIndex} editable droppable={true} droppableId={uniqueId} />
                          <button
                            className="p-0 border-none absolute top-1 right-2 z-10"
                            onClick={(evt) => {
                              evt.preventDefault()
                              evt.stopPropagation()
                              setCurrentWorkflow(workflowItem)
                              setWorkflowEditingMode('edit')
                              setShowWorkflowEditor(true)
                            }}>
                            <EditIcon className="w-5 h-5 fill-white" />
                          </button>
                        </div>
                      </Draggable>
                    )
                  })
                }
                <div>
                  <button
                    type="button"
                    className="add-node-btn"
                    onClick={() => {
                      setCurrentWorkflow({ name: '', description: '' } as Workflow)
                      setShowWorkflowEditor(true)
                      setWorkflowEditingMode('new')
                    }} >
                    + Add Workflow
                  </button>
                </div>
              </div>
              <div className="px-4 basis-[60px]">
                <LongRightIcon className="fill-neutral-80 dark:fill-neutral-10 mt-3" />
              </div>
              <div className="flex-grow flex-col items-start space-y-2">
                {!currentWorkflowTemplate.masterTemplates[0] &&
                  <button
                    type="button"
                    className="node master-workflow-node !text-center"
                    onClick={() => {
                      handleChosenMasterWorkflow()
                    }}>+ Choose a Master Workflow</button>
                }
                {
                  currentWorkflowTemplate.masterTemplates[0] &&
                  <button
                    className="node master-workflow-node"
                    onClick={() => {
                      setShowTemplateDialog(true)
                    }}
                  >
                    {currentWorkflowTemplate.masterTemplates[0].name}
                  </button>
                }
                {
                  currentWorkflowTemplate.masterTemplates[0] &&
                  currentWorkflowTemplate.masterTemplates[0].templateWorkflows.map((workflowItem, workflowIndex) => {
                    const uniqueId = 'master-workflow-' + workflowItem._id + '-' + workflowIndex
                    return (
                      <div className="flex flex-col items-start pt-2 space-y-1" key={uniqueId} >
                        <WorkflowNode workflow={workflowItem} index={workflowIndex} />
                      </div>
                    )
                  })
                }
              </div>
            </div>
          }
        </div>
        {/* Task editor ends*/}
        {/* Task Library begins*/}
        <div className="w-4 bg-transparent"></div>
        <TaskPanel onTaskChange={(updatedTask: WorkflowTask) => {
          const newTemplate = {
            ...currentWorkflowTemplate
          } as WorkflowSubmissionTemplate

          //update tasks in workflow
          if (currentWorkflowTemplate.templateWorkflows) {
            const workflows = currentWorkflowTemplate.templateWorkflows.map((workflow) => {
              workflow.workflowTasks = workflow.workflowTasks.map((task) => {
                if (updatedTask._id === task._id) {
                  return updatedTask
                }
                return task
              })
              return workflow
            })
            newTemplate.templateWorkflows = workflows
          }

          //update tasks in master workflow
          if (currentWorkflowTemplate.masterTemplates && currentWorkflowTemplate.masterTemplates[0]) {
            const masterWorkflows = currentWorkflowTemplate.masterTemplates[0].templateWorkflows.map((workflow) => {
              workflow.workflowTasks = workflow.workflowTasks.map((task) => {
                if (updatedTask._id === task._id) {
                  return updatedTask
                }
                return task
              })
              return workflow
            })
            newTemplate.masterTemplates[0].templateWorkflows = masterWorkflows
          }

          // sync change to UI
          console.log('updated workflows', newTemplate)
          setCurrentWorkflowTemplate(newTemplate)
        }} />
        {/* Task Library ends*/}
      </DndContext>

      <Dialog
        header="Select Master Workflow"
        visible={showTemplateDialog}
        onHide={() => {
          if (!showTemplateDialog) {
            return
          }
          setShowTemplateDialog(false);
        }}
        footer={() => (
          <div className="w-full col-span-8 pt-6 flex items-center justify-end space-x-6">
            <Button
              type="button"
              label="Save"
              onClick={() => {
                if (selectedMasterWorkflow) {
                  if (selectedMasterWorkflow._id === currentWorkflowTemplate._id) {
                    toast.error('Cannot select the same workflow as master workflow.')
                    return
                  }
                  fetchOneWorkflowTemplate(selectedMasterWorkflow._id).then((response) => {
                    if (response.data) { // pase response fro master template
                      const tplData = response.data
                      const fullTemplate = protoService.deSerialize(
                        tplData.protoBytesList[0], 'workflowsubmissiontemplate'
                      ) as WorkflowSubmissionTemplate
                      const newTemplate = {
                        ...currentWorkflowTemplate,
                        masterTemplates: [fullTemplate],
                      } as WorkflowSubmissionTemplate
                      setCurrentWorkflowTemplate(newTemplate)
                      onChange && onChange(newTemplate)
                      setShowTemplateDialog(false)
                    }
                  }).catch((err) => {
                    console.error('Error fetching master workflow', err)
                    toast.error('Error fetching master workflow')
                  })
                } else {
                  toast.error('Please select a master workflow.')
                }
              }}
            />
          </div>
        )}
      >
        <div className="flex flex-col items-stretch">
          <Dropdown
            value={selectedMasterWorkflow}
            pt={{
              ...DropdownStyle,
              root: {
                ...DropdownStyle.root,
                className: 'min-w-full dark:bg-transparent dark:border-neutral-70'
              },
            }}
            filter
            filterBy='name'
            dataKey='_id'
            showClear
            onChange={(e) => {
              console.log('selected master workflow', e.value)
              setSelectedMasterWorkflow(e.value)
            }}
            options={globalWorkflowTemplateList}
            optionLabel="name"
            optionValue="_id"
            useOptionAsValue
            placeholder="Select a Workflow"
            emptyMessage="&nbsp;&nbsp;No Workflows"
            emptyFilterMessage="&nbsp;&nbsp;No workflows found"
          />
        </div>
      </Dialog>
      {showWorkflowEditor &&
        <WorkflowEditor
          mode={workflowEditingMode}
          initWorkflow={currentWorkflow}
          onCancel={() => setShowWorkflowEditor(false)}
          onConfirm={async (workflow: Workflow, mode: string) => {
            setShowWorkflowEditor(false)
            if (currentWorkflowTemplate) {
              const newTemplate = {
                ...currentWorkflowTemplate,
              } as WorkflowSubmissionTemplate
              if (mode === 'new') {
                // this for temporary id, will be replaced by server id
                workflow._id = 'new' + new Date().getTime()
                workflow.workflowTasks = []
                if (newTemplate.templateWorkflows) {
                  newTemplate.templateWorkflows.push(workflow)
                } else {
                  newTemplate.templateWorkflows = []
                }
              } else if (mode === 'edit') {
                newTemplate.templateWorkflows = newTemplate.templateWorkflows.map((workflowItem) => {
                  if (workflowItem._id === workflow._id) {
                    workflowItem.name = workflow.name
                    workflowItem.description = workflow.description
                  }
                  return workflowItem
                })
              }
              setCurrentWorkflowTemplate(newTemplate)
              onChange && onChange(newTemplate)
            }
          }}
        />}
    </div>
  )
}

export default WorkflowTemplateEditor

import {
  ColumnPinningState,
  flexRender,
  getCoreRowModel,
  getSortedRowModel,
  SortDirection,
  SortingState,
  useReactTable
} from '@tanstack/react-table'
import { Button } from 'primereact/button'
import { Checkbox } from 'primereact/checkbox'
import { DataTable, DataTableFilterMeta, DataTablePageEvent, DataTableSortEvent } from 'primereact/datatable'
import { Dropdown } from 'primereact/dropdown'
import { CSSProperties, FC, useEffect, useReducer, useRef, useState } from 'react'
import { toast } from 'react-hot-toast'
import { useNavigate } from 'react-router'
import { TableVirtuoso } from 'react-virtuoso'
import { useRecoilValue } from 'recoil'
import { protoService } from '../proto/ProtoService'
import { LazyTableState, Project, SelectOption } from '../types/types'

import { queryString2JsonString } from '../types/util'
import { CurrentUserKey, DefaultPageSize } from '../utils/constants'
import { currentUserState, geometryListState } from '../utils/state-atoms'
import { useGetRescaleQueue, useReleaseFromQueue, useSetQueuePriority } from '../api/misc-API'
import { set } from 'date-fns'
import { Column } from 'primereact/column'
import { formatDateNumber } from '../utils/intl'
import IconButton from '../components/buttons/IconButton'
import { DeleteIcon, EyeIcon, PlayIcon, PlusIcon, ViewIcon } from '../components/Icons'
import { Dialog } from 'primereact/dialog'
import Modal from '../components/Modal'
import { InputNumber } from 'primereact/inputnumber'
import { Controller, useForm } from 'react-hook-form'
import classNames from 'classnames'
import CloseButton from '../components/buttons/CloseButton'

const columns: any[] = [
  {
    header: 'GM Ref',
    field: 'gmRef',
    body: (rowData) => {
      return <span>{rowData.gmRef}</span>
    }
  },
  {
    header: 'Job ID',
    field: 'jobId',
    body: (rowData) => {
      return <span>{rowData.jobId}</span>
    }
  },
  {
    header: 'Priority',
    field: 'priority',
    body: (rowData) => {
      return <span>{rowData.priority}</span>
    }
  },
  {
    header: 'Queued Time',
    field: 'queuedTime',
    body: (rowData) => {
      return <span>{formatDateNumber(rowData.queuedTime, 'dd/MM/yyyy hh:mm:ss a')}</span>
    }
  },
  {
    header: 'Submitted By',
    field: 'submittedBy',
    body: (rowData) => {
      return <span>{rowData.submittedBy}</span>
    }
  },
  {
    header: 'Queue',
    field: 'queueName',
    body: (rowData) => {
      return <span>{rowData.queueName}</span>
    }
  },
  {
    header: 'Status',
    field: 'submitted',
    body: (rowData) => {
      return <span>{rowData.submitted ? 'Submitted' : 'Waiting'}</span>
    }
  }
]


const defaultFilters: DataTableFilterMeta = {
  vehicle: { value: undefined, matchMode: 'equals' },
  status: { value: undefined, matchMode: 'equals' },
  engineer: { value: undefined, matchMode: 'contains' },
  startDate: { value: undefined, matchMode: 'dateAfter' },
  endDate: { value: undefined, matchMode: 'dateBefore' },
  area: { value: undefined, matchMode: 'equals' },
  byMeOnly: { value: false, matchMode: 'equals' }
}

const refs: SelectOption[] = []
for (let i = 0; i < 1000; i++) {
  refs.push({
    label: i.toString(),
    value: i.toString()
  } as SelectOption)
}

const
  RescaleQueue: FC = () => {
    const [{ data: rescaleQData, loading: loadingQueue, error }, fetchRescaleQueue] = useGetRescaleQueue()
    const [sorting, setSorting] = useState<SortingState>([])
    const [columnPinning, setColumnPinning] = useState<ColumnPinningState>({
      left: ['col0']
    })
    const [rescaleQList, setRescaleQList] = useState<any>([])
    const [queueToShow, setQueueToShow] = useState<any>()
    const [priorityValue, setPriorityValue] = useState(11);
    const [showQueueDetail, setShowQueueDetail] = useState(false)
    const [showReleaseDialog, setShowReleaseDialog] = useState(false)
    const [showSetPriorityDialog, setShowSetPriorityDialog] = useState(false)
    const [{ loading: releasingQueue }, releaseFromQueue] = useReleaseFromQueue()
    const [{ loading: settingPriority }, setQueuePriority] = useSetQueuePriority()
    const refRefresh = useRef(true)

    const {
      control,
      handleSubmit,
      formState: { errors }
    } = useForm<any>()

    const [lazyState, setlazyState] = useState<LazyTableState>({
      first: 0,
      rows: DefaultPageSize,
      page: 1,
      sortField: '',
      sortOrder: -1,
      filters: {
        ...defaultFilters
      },
      sumlvl: 'vehicleStatesList:0,geometryList:0,tyreSetList:0,cfdSequenceList:0,method:0'
    })

    const releaseFooter = (
      <div className="inline-flex">
        <Button className="ml-2" label="Queue ONLY" onClick={() => handleReleaseFromQueueOnly()} />
        <Button className="ml-2 mr-4" label="Release" onClick={() => handleReleaseFromQueue()} />
        <CloseButton label="Close" onClick={() => setShowReleaseDialog(false)} />
      </div>
    );

    useEffect(() => {
      fetchRescaleQueue().catch((err) => {
        console.error(err)
        toast.error('Error loading rescale queue.')
      })
    }, [lazyState])

    useEffect(() => {
      if (rescaleQData) {
        let rescaleQList = JSON.stringify(rescaleQData.jobQueue)
        rescaleQList = rescaleQData.jobQueue.map((element) => {
          element = JSON.parse(element)
          element.jsonPayload = JSON.parse(element.jsonPayload)
          return element
        })
        setRescaleQList(rescaleQList)
      }
    }, [rescaleQData])

    useEffect(() => {
      if (loadingQueue || showQueueDetail) {
        refRefresh.current = false
      } else {
        refRefresh.current = true
      }
    }, [loadingQueue, showQueueDetail])

    useEffect(() => {
      if (loadingQueue || showReleaseDialog) {
        refRefresh.current = false
      } else {
        refRefresh.current = true
      }
    }, [loadingQueue, showReleaseDialog])

    useEffect(() => {
      if (loadingQueue || showSetPriorityDialog) {
        refRefresh.current = false
      } else {
        refRefresh.current = true
      }
    }, [loadingQueue, showSetPriorityDialog])

    useEffect(() => {
      fetchRescaleQueue().catch((err) => {
        console.error(err)
        toast.error('Error loading rescale queue')
      })

      const intervalId = setInterval(() => {
        reloadRescaleQueue()
      }, 5000); // Update every 5 seconds

      return () => clearInterval(intervalId); // Cleanup on unmount
    }, [])

    const reloadRescaleQueue = () => {
      if (!refRefresh.current) {
        return
      }

      fetchRescaleQueue().catch((err) => {
        console.error(err)
        toast.error('Error loading workflow submission.')
      })
    }

    const handleReleaseFromQueue = () => {
      const gmRef = queueToShow.gmRef
      setShowReleaseDialog(false)
      releaseFromQueue({ gmRefString: encodeURI(gmRef), releaseFromQueueOnly: false } as any).then(() => {
        toast.success('Successfully released from queue.')
      }).catch((err) => {
        console.error(err)
        toast.error(err?.response?.data || 'Error releasing from queue.')
      })
    }

    const handleReleaseFromQueueOnly = () => {
      const gmRef = queueToShow.gmRef
      setShowReleaseDialog(false)
      releaseFromQueue({ gmRefString: encodeURI(gmRef), releaseFromQueueOnly: false } as any).then(() => {
        toast.success('Successfully released from queue.')
      }).catch((err) => {
        console.error(err)
        toast.error(err?.response?.data || 'Error releasing from queue.')
      })
    }

    const handleSetQueuePriority = (priority) => {
      const gmRef = queueToShow.gmRef
      setShowSetPriorityDialog(false)
      setQueuePriority({ gmRefString: encodeURI(gmRef), priority: priority } as any).then(() => {
        toast.success('Successfully set queue priority.')
      }).catch((err) => {
        console.error(err)
        toast.error(err?.response?.data || 'Error setting queue priority.')
      })

    }

    function handlePrioritySubmit(formData) {
      handleSetQueuePriority(formData.priority.value)
    }

    return (
      <div className="basis-0 grow flex flex-col items-stretch mt-2 min-w-full">
        <DataTable
          value={rescaleQList}
          first={lazyState.first}
          dataKey="gmRef"
          stripedRows
          rows={lazyState.rows}
          pt={{
            root: {
              className: 'mt-8'
            },
            bodyRow: {
              className: 'table-row'
            },
          }}>
          {columns.map((item) => {
            return (
              <Column
                key={item.field}
                field={item.field}
                header={item.header}
                body={item.body}
                sortable={item.sortable}
                headerClassName={item.headerClassName}
                bodyClassName={item.bodyClassName}></Column>
            )
          })}
          <Column
            body={(rowData, options) => {
              return (
                <div className="flex items-center justify-center">
                  <span>
                    <IconButton
                      severity="info"
                      icon={<EyeIcon className="fill-white dark:fill-light-blue h-4 w-4" />}
                      onClick={() => {
                        setShowQueueDetail(true)
                        setQueueToShow(rowData)
                      }}
                      title="View Case Details"
                    />
                  </span>
                  <span className="ml-1">
                    <IconButton
                      severity='secondary'
                      icon={<PlayIcon className="fill-white dark:fill-light-blue h-4 w-4" />}
                      onClick={() => {
                        setShowReleaseDialog(true)
                        setQueueToShow(rowData)
                      }}
                    />
                  </span>
                  <span className="ml-1">
                    <IconButton
                      severity='secondary'
                      icon={<PlusIcon className="fill-white dark:fill-light-blue h-4 w-4" />}
                      onClick={() => {
                        setShowSetPriorityDialog(true)
                        setQueueToShow(rowData)
                      }}
                    />
                  </span>
                </div>
              )
            }}
            headerClassName="one-col-editor-header editor-header"
            headerStyle={{ width: '50px' }}
            pt={{
              root: {
                className: 'px-2 py-2 text-center'
              }
            }}></Column>
        </DataTable>
        <Dialog
          header={`${queueToShow?.jobId} Details`}
          visible={showQueueDetail}
          closeOnEscape
          pt={
            {
              root: {
                className: 'w-[60vw]'
              },
              mask: {
                className: 'bg-blue-backdrop dark:bg-neutral-80'
              },
              content: {
                className: 'max-h-[80vh] overflow-y-auto'
              }
            }
          }
          onHide={() => { if (!showQueueDetail) return; setShowQueueDetail(false); }}>
          <p>
            <pre className="whitespace-pre-wrap">
              {queueToShow && JSON.stringify(queueToShow, null, 4)}
            </pre>
          </p>
        </Dialog>
        <Dialog
          header={`Release ${queueToShow?.jobId}`}
          visible={showReleaseDialog}
          closeOnEscape
          footer={releaseFooter}
          onHide={() => { if (!showReleaseDialog) return; setShowReleaseDialog(false); }}>
          <p>
            Are you sure you want to release {queueToShow?.jobId} from the queue?
          </p>
        </Dialog>
        <Dialog
          header={`Set Priority for ${queueToShow?.jobId}`}
          visible={showSetPriorityDialog}
          closeOnEscape
          onHide={() => { if (!showSetPriorityDialog) return; setShowSetPriorityDialog(false); }}>
          <form onSubmit={handleSubmit(handlePrioritySubmit)}>
            <Controller
              // @ts-ignore
              name="priority"
              control={control}
              rules={{ required: 'Priority is required.' }}
              render={({ field, fieldState }) => (
                <>
                  <label htmlFor="priority" className="mb-2">
                    Set Priority:
                  </label>
                  <InputNumber
                    id={field.name}
                    {...field}
                    value={priorityValue}
                    onValueChange={(e) => setPriorityValue(e.value || 11)}
                    mode="decimal"
                    inputClassName={classNames({ error: fieldState.error })}
                  />
                  <div className="w-full col-span-8 pt-6 flex items-center justify-end space-x-6">
                    <Button
                      type="submit"
                      label="Set Priority"
                    />
                    <CloseButton label="Close" onClick={() => { if (!showSetPriorityDialog) return; setShowSetPriorityDialog(false); }} />
                  </div>
                </>
              )}

            />
          </form>
        </Dialog>
      </div>
    )
  }

export default RescaleQueue

import {
  Geometry,
  Methodology,
  Project,
  ProjectConfig,
  ProjectStatus,
  ProjectStatusObj,
  RunConfigProto,
  RunStatus,
  RunStatusObj,
  SelectOption,
  TableColDef,
  TyreSet,
  Vehicle,
  Workflow,
  WorkflowTask,
} from '../types/types'

export const TeamId = 'TestingTeam'

export const CurrentUserKey = 'CURRENT_USER'
export const AccessTokenKey = 'ACCESS_TOKEN'
export const RescaleTokenKey = 'RESCALE_ACCESS_TOKEN'
export const GMMSRaceTeamKey = 'GMMS_RACE_TEAM_ID'

export const RecentSequenceKey = 'RECENT_SEQUENCE_KEY'
export const RecentGeometryKey = 'RECENT_GEOMETRY_KEY'
export const RecentCarStorageKey = 'RECENT_CAR_STORAGE_KEY'

export const MaxSavedSequence = 10
export const MaxSavedGeometry = 20

export const DefaultPageSize = 10

export const CompactTableBodyClassName = '!p-1'
export const CompactTableHeaderClassName = 'py-1 px-1'

export const DummyWorkflow = new Workflow()
export const DummyWorkflowTask = new WorkflowTask()
export const DummyRunConfig = new RunConfigProto()
export const DummyTyreSet = new TyreSet()
export const DummyVehicle = new Vehicle()
export const DummyProjectConfig = new ProjectConfig()
export const DummyProject = new Project()
DummyProject.raceTeamId = 'geoffsTeam'
DummyProject.status = ProjectStatus.ACTIVE
DummyProject.contributors = []
DummyProject.isBaseline = false

export const DummyMethodology = new Methodology()
DummyMethodology.raceTeamId = 'TestingTeam'

export const DummyGeometry = new Geometry()
DummyGeometry.raceTeamId = TeamId

const DummyGeo2 = new Geometry()
DummyGeo2.raceTeamId = TeamId

export const DummyGeometryList: Geometry[] = []

export const ProjectStatusDef = {
  [ProjectStatus.ACTIVE]: {
    name: 'Active'
  },
  [ProjectStatus.ARCHIVED]: {
    name: 'Archived'
  },
}

export const WorkflowStatusDef = {
  [RunStatus.COMPLETED]: {
    name: 'Completed'
  },
  [RunStatus.CANCELLED]: {
    name: 'Cancelled'
  },
  [RunStatus.WARN_ON_ERROR]: {
    name: 'WarnOnError'
  },
  [RunStatus.CREATED]: {
    name: 'Created'
  },
  [RunStatus.WAITING]: {
    name: 'Waiting'
  },
  [RunStatus.ERROR]: {
    name: 'Error'
  },
  [RunStatus.RUNNING]: {
    name: 'Running'
  },
  [RunStatus.SUBMITTED]: {
    name: 'Submitted'
  },
  [RunStatus.UNSPECIFIED]: {
    name: 'Unspecified'
  },
  [RunStatus.PENDING]: {
    name: 'Pending'
  }
}

export const ProjectStatusList: ProjectStatusObj[] = [
  {
    value: ProjectStatus.ACTIVE,
    name: ProjectStatusDef[ProjectStatus.ACTIVE].name
  },
  {
    value: ProjectStatus.ARCHIVED,
    name: ProjectStatusDef[ProjectStatus.ARCHIVED].name
  }
]

export const SubmissionStatusList: RunStatusObj[] = [
  {
    value: RunStatus.CREATED,
    name: WorkflowStatusDef[RunStatus.CREATED].name
  },
  {
    value: RunStatus.SUBMITTED,
    name: WorkflowStatusDef[RunStatus.SUBMITTED].name
  },
  {
    value: RunStatus.PENDING,
    name: WorkflowStatusDef[RunStatus.PENDING].name
  },
  {
    value: RunStatus.RUNNING,
    name: WorkflowStatusDef[RunStatus.RUNNING].name
  },
  {
    value: RunStatus.ERROR,
    name: WorkflowStatusDef[RunStatus.ERROR].name
  },
  {
    value: RunStatus.WARN_ON_ERROR,
    name: WorkflowStatusDef[RunStatus.WARN_ON_ERROR].name
  },
  {
    value: RunStatus.COMPLETED,
    name: WorkflowStatusDef[RunStatus.COMPLETED].name
  },
  {
    value: RunStatus.CANCELLED,
    name: WorkflowStatusDef[RunStatus.CANCELLED].name
  }
]

export const TaskTypes: SelectOption[] = [
  {
    value: 'platform',
    label: 'Platform'
  },
  {
    value: 'rescale',
    label: 'Rescale'
  }
]

export const Areas: SelectOption[] = [
  {
    value: 'Rear Wing',
    label: 'Rear Wing'
  },
  {
    value: 'Front Wing',
    label: 'Front Wing'
  },
  {
    value: 'Rear Corner',
    label: 'Rear Corner'
  },
  {
    value: 'Floor Fillet',
    label: 'Floor Fillet'
  },
  {
    value: 'Front Corner',
    label: 'Front Corner'
  },
  {
    value: 'Rear Bodywork',
    label: 'Rear Bodywork'
  },
  {
    value: 'Floor Bodywork',
    label: 'Floor Bodywork'
  },
]

export const VehicleStateColDefs: TableColDef[] = [
  {
    title: 'VS',
    field: 'vehicleStateId'
  },
  {
    title: 'AHF',
    field: 'ahf'
  },
  {
    title: 'AHR',
    field: 'ahr'
  },
  {
    title: 'Roll',
    field: 'roll'
  },
  {
    title: 'Yaw',
    field: 'yaw'
  },
  {
    title: 'Cnr Rad',
    field: 'cornerRad'
  },
  {
    title: 'Steer',
    field: 'steer'
  },
  {
    title: 'Speed',
    field: 'speed'
  },
  {
    title: 'FF',
    field: 'ff'
  },
  {
    title: 'RF',
    field: 'rf'
  },
  {
    title: 'RR',
    field: 'rr'
  },
  {
    title: 'Exh',
    field: 'exh'
  },
  {
    title: 'DRS%',
    field: 'drs'
  }
]

export const VehicleStateEditColDefs: TableColDef[] = [
  {
    title: 'AHF',
    field: 'ahf'
  },
  {
    title: 'AHR',
    field: 'ahr'
  },
  {
    title: 'Roll',
    field: 'roll'
  },
  {
    title: 'Yaw',
    field: 'yaw'
  },
  {
    title: 'Cnr Rad',
    field: 'cornerRad'
  },
  {
    title: 'Steer',
    field: 'steer'
  },
  {
    title: 'Speed',
    field: 'speed'
  },
  {
    title: 'FF',
    field: 'ff'
  },
  {
    title: 'RF',
    field: 'rf'
  },
  {
    title: 'RR',
    field: 'rr'
  },
  {
    title: 'Exh',
    field: 'exh'
  },
  {
    title: 'DRS%',
    field: 'drs'
  }
]

export const SimulationTypes: SelectOption[] = [
  {
    value: 'half',
    label: 'Half Car'
  },
  {
    value: 'full',
    label: 'Full Car'
  }
]

export const VSTableColumns = [
  {
    header: 'Name',
    field: 'name',
    headerClassName: CompactTableHeaderClassName,
    bodyClassName: CompactTableBodyClassName
  },
  {
    header: 'VS',
    field: 'vehicleStateId',
    headerClassName: CompactTableHeaderClassName,
    bodyClassName: CompactTableBodyClassName
  },
  {
    header: 'AHF',
    field: 'ahf',
    headerClassName: CompactTableHeaderClassName,
    bodyClassName: CompactTableBodyClassName
  },
  {
    header: 'AHR',
    field: 'ahr',
    headerClassName: CompactTableHeaderClassName,
    bodyClassName: CompactTableBodyClassName
  },
  {
    header: 'Steer',
    field: 'steer',
    headerClassName: CompactTableHeaderClassName,
    bodyClassName: CompactTableBodyClassName
  },
  {
    header: 'Roll',
    field: 'roll',
    headerClassName: CompactTableHeaderClassName,
    bodyClassName: CompactTableBodyClassName
  },
  {
    header: 'Yaw',
    field: 'yaw',
    headerClassName: CompactTableHeaderClassName,
    bodyClassName: CompactTableBodyClassName
  },
  {
    header: 'Cnr Rad',
    field: 'cornerRad',
    headerClassName: CompactTableHeaderClassName,
    bodyClassName: CompactTableBodyClassName
  },
  {
    header: 'FF',
    field: 'ff',
    headerClassName: CompactTableHeaderClassName,
    bodyClassName: CompactTableBodyClassName
  },
  {
    header: 'RF',
    field: 'rf',
    headerClassName: CompactTableHeaderClassName,
    bodyClassName: CompactTableBodyClassName
  },
  {
    header: 'Speed',
    field: 'speed',
    headerClassName: CompactTableHeaderClassName,
    bodyClassName: CompactTableBodyClassName
  },
  {
    header: 'RR',
    field: 'rr',
    headerClassName: CompactTableHeaderClassName,
    bodyClassName: CompactTableBodyClassName
  },
  {
    header: 'Exh',
    field: 'exh',
    headerClassName: CompactTableHeaderClassName,
    bodyClassName: CompactTableBodyClassName
  },
  {
    header: 'DRS%',
    field: 'drs',
    headerClassName: CompactTableHeaderClassName,
    bodyClassName: CompactTableBodyClassName
  }
]

export const VS_STATUS_ACTIVE = 'Active'
export const VS_STATUS_DELETED = 'Deleted'

export const STATUS_TEST = 'Test'
export const STATUS_RELEASE = 'Release'

const theYear = new Date().getFullYear()
export const carList = [theYear, theYear + 1]

export const DataStatusList: SelectOption[] = [
  {
    value: STATUS_TEST,
    label: STATUS_TEST
  },
  {
    value: STATUS_RELEASE,
    label: STATUS_RELEASE
  }
]

export const methodTemplate = {
  id: '',
  desc: '',
  created_by: '',
  updated_on: '',
  created_on: '',
  updated_by: '',
  dictionary: {
    name: '',
    domain: {
      WT: {
        code: {
          path: '',
          setup_path: ''
        },
        template: {
          path: ''
        }
      }
    },
    star_version_server: '',
    star_version_rescale: ''
  },
  local_id: '00007',
  released: true
}

export const tyreSetTemplate = {
  id: '',
  desc: '',
  created_by: '',
  updated_on: '',
  created_on: '',
  updated_by: '',
  dictionary: {
    name: 'Set_v001',
    states: {
      Standard: {
        fl: {
          cp_cs: [
            [0, 0, 0],
            [0, 0, 0],
            [0, 0, 0]
          ],
          axis_cs: [
            [0, 0, 0],
            [0, 0, 0],
            [0, 0, 0]
          ]
        },
        fr: {
          cp_cs: [
            [0, 0, 0],
            [1, 0, 0],
            [0, -1, 0]
          ],
          axis_cs: [
            [0, 0, 0],
            [0, 0, 0],
            [0, 0, 0]
          ]
        },
        rl: {
          cp_cs: [
            [0, 0, 0],
            [0, 0, 0],
            [0, 0, 0]
          ],
          axis_cs: [
            [0, 0, 0],
            [0, 0, 0],
            [0, 0, 0]
          ]
        },
        rr: {
          cp_cs: [
            [0, 0, 0],
            [1, 0, 0],
            [0, 0, 0]
          ],
          axis_cs: [
            [0, 0, 0],
            [0, 0, 0],
            [0, 0, 0]
          ]
        },
        cad: '24S40-01-0011, 24S40-02-0013'
      }
    },
    description: ''
  },
  local_id: '',
  released: true
}

export const projectConfigTemplate = {
  id: '',
  desc: '',
  created_by: '',
  updated_on: '',
  created_on: '',
  updated_by: '',
  dictionary: {
    headline: {
      Cd: {
        dp: 3,
        gain: '-',
        invert: 'false',
        monitor: 'CD Monitor'
      },
      Cl: {
        dp: 3,
        gain: '-',
        invert: 'false',
        monitor: 'CL Monitor'
      },
      Cs: {
        dp: 3,
        gain: '-',
        invert: 'false',
        monitor: 'CS Monitor'
      },
      ClF: {
        dp: 3,
        gain: '-',
        invert: 'false',
        monitor: 'CLf Monitor'
      },
      ClR: {
        dp: 3,
        gain: '-',
        invert: 'false',
        monitor: 'CLr Monitor'
      },
      FAB: {
        dp: 1,
        gain: 'NA',
        invert: 'false',
        monitor: 'derived'
      },
      'FW Cd': {
        dp: 3,
        gain: '-',
        invert: 'false',
        monitor: 'FW_CD Monitor'
      },
      'FW Cl': {
        dp: 3,
        gain: '-',
        invert: 'false',
        monitor: 'FW_CL Monitor'
      },
      'RW Cd': {
        dp: 3,
        gain: '-',
        invert: 'false',
        monitor: 'RW_CD Monitor'
      },
      'RW Cl': {
        dp: 3,
        gain: '-',
        invert: 'false',
        monitor: 'RW_CL Monitor'
      },
      'Cd Body': {
        dp: 3,
        gain: '-',
        invert: 'false',
        monitor: 'Sprung_CD Monitor'
      },
      'Cl Body': {
        dp: 3,
        gain: '-',
        invert: 'false',
        monitor: 'Sprung_CL Monitor'
      },
      'Cs Body': {
        dp: 3,
        gain: '-',
        invert: 'false',
        monitor: 'Sprung_CS Monitor'
      },
      'ClF Body': {
        dp: 3,
        gain: '-',
        invert: 'false',
        monitor: 'Sprung_CLf Monitor'
      },
      'ClR Body': {
        dp: 3,
        gain: '-',
        invert: 'false',
        monitor: 'Sprung_CLr Monitor'
      },
      'FAB Body': {
        dp: 1,
        gain: 'NA',
        invert: 'false',
        monitor: 'derived'
      },
      'LH Rad (kg/s)': {
        dp: 2,
        gain: '+',
        invert: 'true',
        monitor: 'RAD1_mdot Monitor (kg/s)'
      },
      'RH Rad (kg/s)': {
        dp: 2,
        gain: '+',
        invert: 'true',
        monitor: 'RAD2_mdot Monitor (kg/s)'
      },
      'Ctr Rad (kg/s)': {
        dp: 2,
        gain: '+',
        invert: 'true',
        monitor: 'RAD3_mdot Monitor (kg/s)'
      }
    }
  },
  local_id: '',
  released: true
}


export const cfdSequenceTemplate = {
  id: '',
  desc: '',
  created_by: '',
  updated_on: '',
  created_on: '',
  updated_by: '',
  dictionary: {
    name: '',
    states: {
      EoS: {
        FRH: 0,
        RRH: 0,
        yaw: 0,
        roll: 0,
        speed: 0,
        steer: 0,
        radius: 0,
        exhaust: 0.0
      },
      HSA: {
        FRH: 0,
        RRH: 0,
        yaw: 0,
        roll: 0.0,
        speed: 0,
        steer: 3,
        radius: 0,
        exhaust: 0.0
      },
      MSA: {
        FRH: 0,
        RRH: 0,
        yaw: 0,
        roll: 0.0,
        speed: 0,
        steer: 6,
        radius: 0,
        exhaust: 0.0
      },
      EXIT: {
        FRH: 30,
        RRH: 45,
        yaw: 0,
        roll: 0.0,
        speed: 0,
        steer: 0,
        radius: 0,
        exhaust: 0.0
      },
      LSA0Y: {
        FRH: 30,
        RRH: 60,
        yaw: 0,
        roll: 0.0,
        speed: 0,
        steer: 0,
        radius: 0,
        exhaust: 0.0
      },
      'LSA+3Y': {
        FRH: 0,
        RRH: 0,
        yaw: 3,
        roll: 0.0,
        speed: 0,
        steer: 0,
        radius: 0,
        exhaust: 0.0
      },
      'LSA+6Y': {
        FRH: 0,
        RRH: 0,
        yaw: 6,
        roll: 0.0,
        speed: 0,
        steer: 8,
        radius: 0,
        exhaust: 0.0
      },
      'LSA+9Y': {
        FRH: 30,
        RRH: 60,
        yaw: 9,
        roll: 0,
        speed: 0,
        steer: 0,
        radius: 0,
        exhaust: 0.0
      },
      'LSA-3Y': {
        FRH: 0,
        RRH: 0,
        yaw: 0,
        roll: 0.0,
        speed: 0,
        steer: 0,
        radius: 0,
        exhaust: 0.0
      },
      'LSA-6Y': {
        FRH: 0,
        RRH: 0,
        yaw: 0,
        roll: 0.0,
        speed: 0,
        steer: 0,
        radius: 0,
        exhaust: 0.0
      },
      'LSA-9Y': {
        FRH: 0,
        RRH: 0,
        yaw: 0,
        roll: 0,
        speed: 0,
        steer: 0,
        radius: 0,
        exhaust: 0.0
      },
      BRAKING: {
        FRH: 0,
        RRH: 0,
        yaw: 0,
        roll: 0.0,
        speed: 0,
        steer: 0,
        radius: 0,
        exhaust: 0.0
      }
    }
  },
  local_id: '',
  released: true
}

export const workflowTemplate = {
  id: '',
  desc: '',
  created_by: '',
  updated_on: '',
  created_on: '',
  updated_by: '',
}

export const simpleRunProjection = encodeURIComponent(
  JSON.stringify({
    _id: 1,
    name: 1,
    shortName: 1,
  })
)

import axios, { AxiosError } from 'axios'
import { Button } from 'primereact/button'
import { InputText } from 'primereact/inputtext'
import { classNames } from 'primereact/utils'
import { FC, useEffect, useState } from 'react'
import { Controller, SubmitHandler, useForm } from 'react-hook-form'
import { toast } from 'react-hot-toast'
import { Workflow } from '../types/types'
import Modal from '../Modal'

type WorkflowEditorProps = {
  mode: string
  initWorkflow: Workflow
  onConfirm: (workflow: Workflow, mode: string) => void
  onCancel: () => void
}

const editorTitle = {
  new: 'Create New Workflow',
  edit: 'View/Edit Workflow'
}

const WorkflowEditor: FC<WorkflowEditorProps> = ({ mode, initWorkflow, onConfirm, onCancel }) => {
  const {
    control,
    handleSubmit,
    setValue,
    formState: { errors }
  } = useForm<Workflow>()

  useEffect(() => {
    setValue('name', initWorkflow.name)
    setValue('description', initWorkflow.description)
  }, [])

  const getFormErrorMessage = (name) => {
    return errors[name] ? (
      <small className="error">{errors[name].message}</small>
    ) : (
      <small className="error">&nbsp;</small>
    )
  }

  const onSubmit: SubmitHandler<Workflow> = (formData: Workflow) => {
    const newWorkflow = {
      ...formData,
    } as Workflow
    if (mode === 'new') {
      newWorkflow._id = ''
      onConfirm && onConfirm(newWorkflow, mode)
    } else if (mode === 'edit') {
      newWorkflow._id = initWorkflow._id
      onConfirm && onConfirm(newWorkflow, mode)
    }
  }

  const handleConfirm = () => {
    onCancel && onCancel()
  }

  return (
    <Modal
      size="md"
      isOpen={true}
      withAction={false}
      easyClose={true}
      onCancel={() => onCancel && onCancel()}
      title={editorTitle[mode]}
      showCancelButton={true}
      zIndex={30}
      onConfirm={handleConfirm}>
      <div className="relative">
        <form
          onSubmit={handleSubmit(onSubmit)}
          className="w-full grid grid-cols-3 gap-x-10 gap-y-4 mt-8">
          <div className="field-box col-span-1">
            <Controller
              name="name"
              control={control}
              rules={{ required: 'Name is required.' }}
              defaultValue={initWorkflow && initWorkflow.name}
              render={({ field, fieldState }) => (
                <>
                  <label htmlFor={field.name} className="mb-2">
                    Name
                  </label>
                  <InputText
                    id={field.name}
                    value={field.value}
                    className={classNames({ error: fieldState.error })}
                    onChange={(e) => field.onChange(e.target.value)}
                  />
                  {getFormErrorMessage(field.name)}
                </>
              )}
            />
          </div>
          <div className="field-box col-span-2">
            <Controller
              name="description"
              control={control}
              defaultValue={initWorkflow && initWorkflow.description}
              rules={{ required: 'Description is required.' }}
              render={({ field, fieldState }) => (
                <>
                  <label htmlFor={field.name} className="mb-2">
                    Description
                  </label>
                  <InputText
                    id={field.name}
                    {...field}
                    className={classNames({ error: fieldState.error })}
                  />
                  {getFormErrorMessage(field.name)}
                </>
              )}
            />
          </div>
          <div className="flex items-center justify-end space-x-4 col-span-3">
            <Button type="submit" label="Save" />
          </div>
        </form>
      </div>
    </Modal>
  )
}

export default WorkflowEditor

import { Button } from 'primereact/button'
import { InputText } from 'primereact/inputtext'
import { FC, useEffect, useState } from 'react'
import { PlatformJob, RescaleAnalysisVariable, RescaleHardwareVariable, RescaleJob, RescaleJobAnalysis, WorkflowTask } from '../../types/types'
import { EditIcon, PlusIcon, SearchIcon } from '../Icons'
import TaskEditor from './TaskEditor'
import Draggable from '../dnd/Draggable'
import { useGetWorkflowTasks, useCreateWorkflowTask, useUpdateWorkflowTask } from '../../api/workflow-task-API'
import toast from 'react-hot-toast'
import { protoService } from '../../proto/ProtoService'
import { ProgressSpinner } from 'primereact/progressspinner'
type TaskPanelProps = {
  onTaskChange: (task: WorkflowTask) => void
}

const TaskPanel: FC<TaskPanelProps> = ({ onTaskChange }) => {
  const [mode, setMode] = useState('')
  const [filterKey, setFilterkey] = useState('')
  const [taskList, setTaskList] = useState<WorkflowTask[]>([])
  const [showTaskEditor, setShowTaskEditor] = useState(false)
  const [{ loading: loadingTasks }, fetchTasks] = useGetWorkflowTasks()
  const [{ loading: creatingTask }, createTask] = useCreateWorkflowTask()
  const [{ loading: updatingTask }, updateTask] = useUpdateWorkflowTask()
  const [currentTask, setCurrentTask] = useState<WorkflowTask>({} as WorkflowTask)

  useEffect(() => {
    fetchTasks().then((res) => {
      const taskData = res.data
      if (taskData) {
        const tmpList = taskData.protoBytesList.map((item: string) => {
          const result = protoService.deSerialize(item, 'workflowtask')
          return result
        })
        tmpList && setTaskList(tmpList)
      }

    }).catch((err) => {
      console.error(err)
      toast.error('Error loading tasks.')
    })
  }, [])

  const handleCreateNewTask = () => {
    setCurrentTask({
      name: 'New Task',
      dependent: false,
      description: '',
      type: 'platform',
      platformJob: {
        name: '',
        description: '',
        platformCopyConfig: {}
      } as PlatformJob,
      rescaleJob: {
        name: '',
        jobanalyses: [{
          command: '',
          analysis: {
            code: '',
            version: '',
          } as RescaleAnalysisVariable,
          hardware: {
            coresPerSlot: 0,
            coreType: 'standard',
            walltime: 0
          } as RescaleHardwareVariable,
        } as RescaleJobAnalysis]
      } as RescaleJob
    } as WorkflowTask)
    setMode('new')
    setShowTaskEditor(true)
  }

  return (
    <div className="basis-[300px] bg-neutral-10 dark:bg-transparent dark:border dark:border-gray-600 p-4 rounded-lg flex-col items-stretch space-y-3">
      <div className="flex items-center justify-between mb-2">
        <h2 className="text-lg">Task Library</h2>
        <Button onClick={handleCreateNewTask} severity="secondary" className="icon-button" tooltip='Create New Task' tooltipOptions={{ position: "bottom" }} data-pr-at='center+40 bottom'>
          <PlusIcon className="fill-white" />
        </Button>
      </div>
      <div className="relative">
        <InputText
          placeholder="Search Tasks"
          className="w-full"
          onChange={(e) => {
            setFilterkey(e.target.value)
          }}

        />
        <SearchIcon className="absolute top-1/2 right-2 transform -translate-y-1/2 fill-neutral-80 dark:fill-neutral-10" />
      </div>
      <div className="flex-grow max-h-[500px] overflow-y-auto flex-col items-stretch space-y-2">
        {loadingTasks && <div className="card flex justify-content-center">
          <ProgressSpinner
            strokeWidth="4"
            pt={{
              root: {
                className: "w-8 h-8",
              },
              circle: {
                className: "!stroke-gm-blue",
              },
            }}
          />
        </div>}
        {taskList && taskList.map((taskItem) => {
          const uniqueId = 'task-' + taskItem._id
          const taskName = (taskItem.platformJob && taskItem.platformJob.name) || (taskItem.rescaleJob && taskItem.rescaleJob.name) || taskItem.name
          if (!filterKey || (filterKey && taskName.toLowerCase().includes(filterKey.toLowerCase()))) {
            return (
              <Draggable id={uniqueId} data={taskItem} key={uniqueId}>
                <div
                  className="bg-neutral-80 text-neutral-10 p-2 rounded-md w-full text-left hover:shadow-md transition-all flex items-center justify-between"
                >
                  <span>{taskName}</span>
                  <button
                    className="p-0 border-none"
                    onClick={(evt) => {
                      evt.preventDefault()
                      evt.stopPropagation()
                      setCurrentTask(taskItem)
                      setMode('edit')
                      setShowTaskEditor(true)
                    }}>
                    <EditIcon className="w-5 h-5 fill-white" />
                  </button>

                </div>
              </Draggable>
            )
          } else {
            return null
          }
        })}
      </div>
      {showTaskEditor &&
        <TaskEditor
          mode={mode}
          saving={creatingTask || updatingTask}
          initTask={currentTask}
          onConfirm={(task) => {
            // ensure the task
            if (taskList.find((item) => item.name === task.name && item._id !== task._id)) {
              toast.error('Task with the same name already exists.')
              return
            }
            const dataToPost = protoService.encodePayload(task, 'workflowtask')
            if (mode === 'new') {
              createTask({ data: dataToPost }).then((res) => {
                const taskData = res.data
                if (taskData) {
                  const tmpList = taskData.protoBytesList.map((item: string) => {
                    const result = protoService.deSerialize(item, 'workflowtask')
                    return result
                  })
                  // show the new one at the top of the list
                  tmpList && setTaskList([tmpList[0], ...taskList])
                  setShowTaskEditor(false)
                }
              }).catch((err) => {
                console.error(err)
                toast.error('Error creating task.')
              })
            } else if (mode === 'edit') {
              updateTask({ id: currentTask._id, data: dataToPost } as any).then((res) => {
                const taskData = res.data
                if (taskData) {
                  const tmpList = taskData.protoBytesList.map((item: string) => {
                    const result = protoService.deSerialize(item, 'workflowtask')
                    return result
                  })
                  if (tmpList.length) {
                    const updatedList = taskList.map((item) => {
                      if (item._id === tmpList[0]._id) {
                        return tmpList[0]
                      } else {
                        return item
                      }
                    })
                    setTaskList(updatedList)
                    onTaskChange && onTaskChange(tmpList[0])
                  }
                  setShowTaskEditor(false)
                }
                setShowTaskEditor(false)
              }).catch((err) => {
                console.error(err)
                toast.error('Error updating task.')
              })
            }
          }}
          onCancel={() => setShowTaskEditor(false)} />
      }
    </div>
  )
}

export default TaskPanel

import { Button } from 'primereact/button'
import { FC, useEffect, useState } from 'react'
import Modal from './Modal'
import CloseButton from './buttons/CloseButton'
import { classNames } from 'primereact/utils'
import { Controller, useForm } from 'react-hook-form'
import { InputText } from 'primereact/inputtext'
import { InputNumber } from 'primereact/inputnumber'
import {
  SimulationState
} from '@aero-platform/shared'
import { LoadingIcon, SaveIcon } from './Icons'

const title = {
  'edit': 'Edit State',
  'new': 'Add New State'
}

type SimulationStateEditorModalProps = {
  mode: string,
  states: SimulationState[],
  stateIndex: number,
  saving: boolean,
  onConfirm: (simulationState: SimulationState, mode: string) => void
  onCancel: () => void
}

const SimulationStateEditorModal: FC<SimulationStateEditorModalProps> = ({
  mode,
  states,
  saving,
  stateIndex,
  onConfirm,
  onCancel
}) => {
  const {
    control,
    setValue,
    handleSubmit,
    formState: { errors }
  } = useForm<SimulationState>()
  const [selectedSimulationState] = useState<SimulationState>(
    mode === 'new' ? ({} as SimulationState) : { ...states[stateIndex] } as SimulationState)

  const getFormErrorMessage = (name) => {
    return errors[name] ? (
      <small className="error">{errors[name]?.message}</small>
    ) : (
      <small className="error">&nbsp;</small>
    )
  }

  const handleSave = async (formData) => {
    console.log('formData', formData)
    onConfirm && onConfirm(formData, mode as string)
  }

  const handleConfirm = () => {
    onCancel && onCancel()
  }

  useEffect(() => {
    if (mode === 'edit') {
      setValue('name', selectedSimulationState.name)
      setValue('description', selectedSimulationState.description)
    }
  }, [])

  return (
    <Modal
      isOpen={true}
      withAction={false}
      easyClose={true}
      onCancel={() => onCancel && onCancel()}
      title={title[mode || 'new']}
      showCancelButton={true}
      zIndex={30}
      onConfirm={handleConfirm}>
      <div className='relative'>
        <form
          onSubmit={handleSubmit(handleSave)}
          className="w-full grid grid-cols-3 gap-x-2 gap-y-4 mt-8">
          <div className="field-box col-span-1">
            <Controller
              name="name"
              control={control}
              disabled
              render={({ field, fieldState }) => (
                <>
                  <label htmlFor={field.name} className="mb-2">
                    Name (read only)
                  </label>
                  <InputText
                    id={field.name}
                    {...field}

                    className={classNames({ error: fieldState.error })}
                  />
                  {getFormErrorMessage(field.name)}
                </>
              )}
            />
          </div>

          <div className="field-box col-span-2">
            <Controller
              name="description"
              control={control}
              render={({ field, fieldState }) => (
                <>
                  <label htmlFor={field.name} className="mb-2">
                    Description
                  </label>
                  <InputText
                    id={field.name}
                    {...field}
                    className={classNames({ error: fieldState.error })}
                  />
                </>
              )}
            />
          </div>

          <div className="w-full col-span-3 pt-6 flex items-center justify-end space-x-6">
            <Button type="submit">
              {saving && <LoadingIcon className="fill-white w-4 h-4" />}
              {!saving && <SaveIcon className="fill-white w-4 h-4" />}
              <span className="ml-1">Save</span>
            </Button>
            <CloseButton label="Close" onClick={() => onCancel && onCancel()} />
          </div>
        </form>
      </div >
    </Modal >
  )
}

export default SimulationStateEditorModal

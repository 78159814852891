import { FilterMatchMode } from 'primereact/api'
import { Button } from 'primereact/button'
import { DataTable, DataTableExpandedRows, DataTableFilterMeta, DataTableRowExpansionTemplate } from 'primereact/datatable'
import { Dropdown } from 'primereact/dropdown'
import { FC, useEffect, useState } from 'react'
import { toast } from 'react-hot-toast'
import { useRecoilState, useRecoilValue } from 'recoil'
import { useCreateTyreSet, useGetTyreSet, useGetTyreSets, useUpdateTyreSet, useUpdateTyreState } from '../api/tyreset-API'
import { CollapseIcon, CopyIcon, EditIcon, ExpandIcon, PlusIcon } from '../components/Icons'
import { protoService } from '../proto/ProtoService'
import { LazyTableState, TyreSet } from '../types/types'
import { cloneTyreState, queryString2JsonString } from '../types/util'
import { DefaultPageSize } from '../utils/constants'
import { tyreSetListState, currentTeamState } from '../utils/state-atoms'
import SaveButton from '../components/buttons/SaveButton'
import { Controller, SubmitHandler, useForm } from 'react-hook-form'
import { DropdownStyle, TabMenuStyle } from '../utils/styling-constants'
import { classNames } from 'primereact/utils'
import { InputText } from 'primereact/inputtext'
import { Column, ColumnBodyOptions } from 'primereact/column'
import { TyreLoadedRadius, TyreState, TyreStateTransform } from '@aero-platform/shared'
import IconButton from '../components/buttons/IconButton'
import TyreSetStateEditor from '../components/TyreSetStateEditor'
import { TabMenu } from 'primereact/tabmenu'

const defaultFilters: DataTableFilterMeta = {
  name: { value: null, matchMode: FilterMatchMode.CONTAINS },
  methodId: { value: null, matchMode: FilterMatchMode.EQUALS },
  status: { value: null, matchMode: FilterMatchMode.EQUALS }
}

const tabItems = [
  { label: 'Config' },
  { label: 'Logs' }
];

const TyreSets: FC = () => {
  const currentTeam = useRecoilValue(currentTeamState)
  const [mode, setMode] = useState('')
  const [editorMode, setEditorMode] = useState('')
  const [stateIndex, setStateIndex] = useState(0)
  const [showStateEditor, setShowStateEditor] = useState(false)
  const [{ loading: loadingTyres, data: tyreSetsData }, fetchTyreSets] = useGetTyreSets()
  const [{ loading: loadingTyre, data: oneTyreSetData }, fetchOneTyreSet] = useGetTyreSet()
  const [{ loading: creating }, createTyreSet] = useCreateTyreSet()
  const [{ loading: updating }, updateTyreSet] = useUpdateTyreSet()
  const [{ loading: updatingState }, updateTyreSetState] = useUpdateTyreState()

  const [expandedRows, setExpandedRows] = useState<DataTableExpandedRows>({})

  const [globalTyreSet, setGlobalTyreSet] = useRecoilState(tyreSetListState)
  const [tyreSets, setTyreSets] = useState<TyreSet[]>([])
  const [currentTyreSet, setCurrentTyreSet] = useState({} as TyreSet)
  const [currentTabIndex, setCurrentTabIndex] = useState(0)
  const [tyreStates, setTyreStates] = useState<TyreState[]>([] as TyreState[])
  const [lazyState, setlazyState] = useState<LazyTableState>({
    first: 0,
    rows: DefaultPageSize,
    page: 1,
    sortField: 'name',
    sortOrder: -1,
    filters: defaultFilters,
    sumlvl: 'vehicleStatesList:0,geometryList:0,tyreSetList:0,cfdSequenceList:0,method:0'
  })

  const {
    control,
    handleSubmit,
    setValue,
    reset,
    formState: { errors }
  } = useForm<TyreSet>()

  useEffect(() => {
    if (tyreSetsData) {
      const tmpList = tyreSetsData.protoBytesList.map((item: string) => {
        return protoService.deSerialize(item, 'tyreset')
      })
      tmpList && setTyreSets(tmpList)
    } else {
      setTyreSets([])
    }
  }, [tyreSetsData])

  useEffect(() => {
    if (oneTyreSetData) {
      console.log(oneTyreSetData)
      if (oneTyreSetData.protoBytesList && oneTyreSetData.protoBytesList.length) {
        const tyreSet = protoService.deSerialize(oneTyreSetData.protoBytesList[0], 'tyreset')
        setCurrentTyreSet(tyreSet)
        setTyreStates(tyreSet.tyreStates.map((item) => {
          return { ...item } as TyreState
        }))
      } else {
        setTyreSets([])
        setTyreStates([])
        toast.error('Error loading tyre set.')
      }
    }
  }, [oneTyreSetData])

  const makeQuery = (state) => {
    let query
    console.log(state)

    if (state.filters.name['value']) {
      const nameSearch = { $regex: state.filters.name['value'], $options: 'i' }
      const q = 'name=' + encodeURIComponent(JSON.stringify(nameSearch))
      query = query ? `${query}&${q}` : q
    }

    if (state.filters.methodId['value']) {
      const q = 'methodId=' + state.filters.methodId['value']
      query = query ? `${query}&${q}` : q
    }

    if (state.filters.status['value']) {
      const q = 'status=' + state.filters.status['value']
      query = query ? `${query}&${q}` : q
    }

    query = queryString2JsonString(query)
    query = query ? `query=${query}&limit=${state.rows}` : `limit=${state.rows}`
    query = `${query}&skip=${state.first}&countTotal=true`
    if (state.sortField) {
      const sort = {
        [state.sortField]: state.sortOrder
      }
      query = `${query}&sort=${JSON.stringify(sort)}`
    }

    return query
  }

  useEffect(() => {
    const query = makeQuery(lazyState)
    fetchTyreSets(query).catch((err) => {
      console.error(err)
      toast.error('Error loading tyreSets.')
    })
  }, [lazyState])

  const handleCreateNewTyreSet = () => {
    if (mode === 'new') {
      return
    }
    reset()
    setMode('new')
    setValue('name', "")
    setValue('description', "")
    setCurrentTyreSet({} as TyreSet)
    setTyreStates([])
  }

  const handleCopyTyreSet = (e) => {
    if (mode === 'new') {
      toast.error('Cannot duplicate while creating a new tyre set.')
      return
    }
    if (!currentTyreSet._id) {
      toast.error('Please select a tyre set to duplicate.')
      return
    }

    setMode('new')
    setValue('name', currentTyreSet.name + ' (Copy)')
    setValue('description', currentTyreSet.description)
    setTyreStates(tyreStates.map((item) => {
      return { ...item } as TyreState
    }))
  }

  const handleItemSelected = (tyreSet) => {
    if (!tyreSet) {
      return
    }
    fetchOneTyreSet(tyreSet._id).catch((err) => {
      toast.error('Error loading tyre set.')
    })

    setCurrentTyreSet(tyreSet)
    setMode('edit')
    reset()
    setValue('name', tyreSet.name)
    setValue('description', tyreSet.description)
    if (tyreSet.tyreStates) {
      setTyreStates(tyreSet.tyreStates.map((item) => {
        return { ...item } as TyreState
      }))
    } else {
      setTyreStates([])
    }
  }

  const onSubmit: SubmitHandler<TyreSet> = async (formData: TyreSet) => {
    if (mode === 'new') {  // create new tyre set
      const newTyreSet = new TyreSet(formData)
      newTyreSet.raceTeamId = currentTeam
      newTyreSet.tyreStates = tyreStates.map((item) => {
        return new TyreState(item)
      })
      const dataToPost = protoService.encodePayload(newTyreSet, 'tyreset')
      try {
        const res = await createTyreSet({ data: dataToPost })
        if (res.data.protoBytesList && res.data.protoBytesList.length > 0) {
          const savedTyreSet: TyreSet = protoService.deSerialize(res.data.protoBytesList[0], 'tyreset')
          setTyreSets([...tyreSets, savedTyreSet])
          setCurrentTyreSet(savedTyreSet)
          setGlobalTyreSet([...globalTyreSet, savedTyreSet])
          toast.success('Tyre set created.')
          setMode('edit')
        }
      } catch (err) {
        console.error(err)
        toast.error('Error creating tyre set.')
      }
    } else if (mode === 'edit') { // update existing tyre set
      if (!currentTyreSet._id) {
        return
      }
      const newTyreSet = new TyreSet(formData)
      newTyreSet._id = currentTyreSet._id
      newTyreSet.raceTeamId = currentTeam
      newTyreSet.tyreStates = tyreStates.map((item) => {
        return cloneTyreState(item)
      })
      const dataToPost = protoService.encodePayload(newTyreSet, 'tyreset')
      try {
        await updateTyreSet({
          id: currentTyreSet._id,
          data: dataToPost
        } as any)
        setTyreSets(
          tyreSets.map((item) => {
            if (item._id === currentTyreSet._id) {
              return newTyreSet
            } else {
              return item
            }
          })
        )
        setCurrentTyreSet(newTyreSet)
        toast.success('Tyre set updated.')
      } catch (err) {
        console.error(err)
        toast.error('Error updating tyre set.')
      }
    }

  }

  const getFormErrorMessage = (name) => {
    return errors[name] ? (
      <small className="error">{errors[name]?.message}</small>
    ) : (
      <small className="error">&nbsp;</small>
    )
  }

  const stateColumns = [
    {
      header: 'Name',
      field: 'name',
      filter: true,
      headerStyle: {},
      bodyStyle: {},
      bodyClassName: 'id-cell max-w-[200px] w-[200px]',
      headerClassName: 'max-w-[200px]',
      sortable: true,
      body: (rowData: TyreState) => {
        const rowName = rowData.name
        return (
          <div className='flex items-center justify-between' id="case-name">
            <div
              className="flex items-center space-x-2 cursor-pointer"
              onClick={() => {
                // get current row id => if in expandRows, remove; else, append
                const copyExpandedRows: DataTableExpandedRows = { ...expandedRows }
                if (expandedRows[rowName]) {  // if rowName exists in expandedRows
                  // delete rowName from expandedRows
                  delete copyExpandedRows[rowName]
                } else { // if rowName doesnt exist, add rowName to expandedRows
                  copyExpandedRows[rowName] = true
                }
                setExpandedRows(copyExpandedRows)
              }}>
              {
                expandedRows[rowName] ?
                  <CollapseIcon className="fill-dark-blue dark:fill-light-blue h-6 w-6" /> :
                  <ExpandIcon className="fill-dark-blue dark:fill-light-blue h-6 w-6" />
              }
              <div>{rowName}</div>
            </div>
          </div>

        )
      }
    },
    {
      header: 'Left Front',
      field: 'loadedRadius.LS_FRONT',
      headerClassName: 'max-w-[100px]',
      bodyClassName: 'max-w-[100px] w-[80px]',
      body: (rowData: TyreState) => {
        return (rowData.loadedRadius.LS_FRONT !== undefined) && rowData.loadedRadius.LS_FRONT.toFixed(1)
      }
    },
    {
      header: 'Left Rear',
      field: 'loadedRadius.LS_REAR',
      headerClassName: 'max-w-[100px]',
      bodyClassName: 'max-w-[100px] w-[80px]',
      body: (rowData: TyreState) => {
        return (rowData.loadedRadius.LS_REAR !== undefined) && rowData.loadedRadius.LS_REAR.toFixed(1)
      }
    },
    {
      header: 'Right Front',
      field: 'loadedRadius.RS_FRONT',
      headerClassName: 'max-w-[100px]',
      bodyClassName: 'max-w-[100px] w-[80px]',
      body: (rowData: TyreState) => {
        return (rowData.loadedRadius.RS_FRONT !== undefined) && rowData.loadedRadius.RS_FRONT.toFixed(1)
      }
    },
    {
      header: 'Right Rear',
      field: 'loadedRadius.RS_REAR',
      headerClassName: 'max-w-[100px]',
      bodyClassName: 'max-w-[100px] w-[80px]',
      body: (rowData: TyreState) => {
        return (rowData.loadedRadius.RS_REAR !== undefined) && rowData.loadedRadius.RS_REAR.toFixed(1)
      }
    },
    {
      header: 'FRH',
      field: 'stateTransform.FRH',
      headerClassName: 'max-w-[100px]',
      bodyClassName: 'max-w-[100px] w-[80px]',
      body: (rowData: TyreState) => {
        return (rowData.stateTransform.FRH !== undefined) && rowData.stateTransform.FRH.toFixed(1)
      }
    },
    {
      header: 'RRH',
      field: 'stateTransform.RRH',
      headerClassName: 'max-w-[100px]',
      bodyClassName: 'max-w-[100px] w-[80px]',
      body: (rowData: TyreState) => {
        return (rowData.stateTransform.RRH !== undefined) && rowData.stateTransform.RRH.toFixed(1)
      }
    },
    {
      header: 'Roll',
      field: 'stateTransform.ROLL',
      headerClassName: 'max-w-[100px]',
      bodyClassName: 'max-w-[100px] w-[80px]',
      body: (rowData: TyreState) => {
        return (rowData.stateTransform.ROLL !== undefined) && rowData.stateTransform.ROLL.toFixed(1)
      }
    },
    {
      header: 'Steer',
      field: 'stateTransform.STEER',
      headerClassName: 'max-w-[100px]',
      bodyClassName: 'max-w-[100px] w-[80px]',
      body: (rowData: TyreState) => {
        return (rowData.stateTransform.STEER !== undefined) && rowData.stateTransform.STEER.toFixed(1)
      }
    },
  ]

  return (
    <div className="basis-0 grow flex flex-col items-stretch mt-6 min-w-full space-y-4">
      <div className="flex items-center justify-between pb-4">
        <h1 className="page-title">Tyre Sets</h1>
        <Button
          type="button"
          onClick={handleCreateNewTyreSet}
          severity="secondary"
          className="icon-button !hidden"
          disabled={mode === 'new'}
          tooltip='Create New Tyre Set'
          tooltipOptions={{ position: 'bottom' }}
          data-pr-at='center+45 bottom'
        >
          <PlusIcon className="fill-white" />
        </Button>
      </div>
      <form
        onSubmit={handleSubmit(onSubmit)}
        className="w-full grid grid-cols-2 gap-x-2 gap-y-4 mt-8">
        <div className="flex items-center col-span-2 space-x-2">
          <Dropdown
            filter
            loading={loadingTyres}
            filterBy="name"
            filterMatchMode="contains"
            showFilterClear
            onFilter={(e) => {
              setlazyState({
                ...lazyState,
                filters: {
                  ...lazyState.filters,
                  name: { value: e.filter, matchMode: FilterMatchMode.CONTAINS }
                }
              })
            }}
            value={currentTyreSet}
            emptyMessage="No TyreSets Found"
            pt={{
              ...DropdownStyle,
              root: {
                ...DropdownStyle.root,
                className: 'min-w-[30%] max-w-[50%]'
              },
            }}
            optionValue="_id"
            optionLabel="name"
            useOptionAsValue
            options={tyreSets}
            valueTemplate={(option) => {
              let value
              if (mode === 'new') {
                value = 'New TyreSet'
              } else if (option) {
                value = option.name
              } else {
                value = (currentTyreSet && currentTyreSet.name) || 'Select Tyre Set'
              }
              return <div>{value}</div>
            }}
            itemTemplate={(option) => <div>{option && option._id ? option.name : ''}</div>}
            onChange={(e) => handleItemSelected(e.value)}
            placeholder="Select Tyre Set"
          />
          <Button
            type="button"
            onClick={handleCopyTyreSet}
            className="icon-button hidden"
            tooltip='Duplicate Tyre Set'
            tooltipOptions={{ position: "bottom" }}
            data-pr-at='center+5 bottom'>
            <CopyIcon className="fill-white w-4 h-4" />
          </Button>
          <SaveButton loading={creating || updating} title="Save Tyre Set" />
        </div>
        <div className="col-span-2">
          <TabMenu model={tabItems}
            onChange={(e) => console.log(e)}
            activeIndex={currentTabIndex}
            pt={TabMenuStyle}
            onTabChange={(e) => setCurrentTabIndex(e.index)} />
          <div className='min-h-4'> </div>
          {currentTyreSet && currentTabIndex === 0 &&
            <>
              <section className="grid grid-cols-2 space-x-2">
                <div className="field-box">
                  <Controller
                    name="name"
                    control={control}
                    disabled={updating}
                    rules={{ required: 'Name is required.' }}
                    render={({ field, fieldState }) => (
                      <>
                        <label htmlFor={field.name} className="mb-2">
                          Name
                        </label>
                        <InputText
                          id={field.name}
                          {...field}
                          className={classNames({ error: fieldState.error })}
                        />
                        {getFormErrorMessage(field.name)}
                      </>
                    )}
                  />
                </div>

                <div className="field-box">
                  <Controller
                    name="description"
                    control={control}
                    disabled={updating}
                    render={({ field, fieldState }) => (
                      <>
                        <label htmlFor={field.name} className="mb-2">
                          Description
                        </label>
                        <InputText
                          id={field.name}
                          {...field}
                          className={classNames({ error: fieldState.error })}
                        />
                        {getFormErrorMessage(field.name)}
                      </>
                    )}
                  />
                </div>
              </section>
              <div className="font-semibold py-4">States</div>
              <section className="col-span-2">
                <DataTable
                  value={tyreStates}
                  loading={loadingTyre}
                  dataKey="name"
                  filterDisplay="row"
                  expandedRows={expandedRows}
                  tableClassName="tyre-set-states"
                  rowExpansionTemplate={
                    (rowData: TyreState, options: DataTableRowExpansionTemplate) => {
                      return (
                        <div>
                          {rowData.description &&
                            <section className="grid grid-cols-12 ml-8">
                              <div className="col-span-2 font-semibold">Description</div>
                              <div className="col-span-10">{rowData.description}</div>
                            </section>}
                          {rowData.cadDir &&
                            <section className="grid grid-cols-12 ml-8">
                              <div className="col-span-2 font-semibold">CAD Directory</div>
                              <div className="col-span-10">{rowData.cadDir}</div>
                            </section>
                          }
                          {rowData.cadFileRef && rowData.cadFileRef.length > 0 &&
                            <section className="grid grid-cols-12 ml-8">
                              <div className="col-span-2 font-semibold">CAD Files</div>
                              <ul className="col-span-10">
                                {rowData.cadFileRef.map((item) => {
                                  return <li key={item.file}>{item.name}/{item.file}/{item.path}/{item.version}</li>
                                })}
                              </ul>
                            </section>
                          }
                        </div>
                      )
                    }
                  }
                  stripedRows
                  sortField='vehicleId'
                  pt={{
                    bodyRow: {
                      className: 'table-row'
                    },
                    rowExpansion: {
                      className: 'pt-0'
                    }
                  }}>
                  {stateColumns.map((item) => {
                    return (
                      <Column
                        key={item.field}
                        field={item.field}
                        header={item.header}
                        showFilterMenu={false}
                        sortable={item.sortable}
                        headerStyle={item.headerStyle}
                        headerClassName={item.headerClassName}
                        bodyStyle={item.bodyStyle}
                        body={item.body}
                        bodyClassName={item.bodyClassName}></Column>
                    )
                  })}
                  <Column
                    body={(rowData: TyreState, options: ColumnBodyOptions) => {
                      return (
                        <div className="flex items-center space-x-1 justify-end">
                          <IconButton
                            severity="secondary"
                            icon={<EditIcon className="h-4 w-4 fill-white stroke-transparent" />}
                            onClick={() => {
                              setStateIndex(options.rowIndex)
                              setEditorMode('edit')
                              setShowStateEditor(true)
                            }}
                          >
                          </IconButton>
                        </div>
                      )
                    }}
                    headerClassName="one-col-editor-header editor-header !w-12 max-w-12"
                    bodyClassName="!pr-0 !max-w-12"
                    pt={{
                      headerContent: {
                        className: 'pr-0 min-w-full'
                      },
                      headerTitle: {
                        className: 'pr-0 w-full flex items-center'
                      },
                    }}
                    bodyStyle={{ textAlign: 'center', padding: '12px 8px' }}></Column>
                </DataTable>
              </section>
            </>
          }
        </div>
      </form >

      {showStateEditor &&
        <TyreSetStateEditor
          mode={editorMode}
          stateIndex={stateIndex}
          states={tyreStates}
          saving={updatingState}
          onConfirm={async (stateData, stateMode) => {
            let newState = new TyreState()
            newState.name = tyreStates[stateIndex].name
            newState.description = stateData.description
            newState.loadedRadius = new TyreLoadedRadius(stateData.loadedRadius)
            newState.stateTransform = new TyreStateTransform(stateData.stateTransform)
            try {
              await updateTyreSetState({
                id: currentTyreSet._id,
                data: protoService.encodePayload(newState, 'tyrestate')
              } as any)
              const newStates = tyreStates.map((item, index) => {
                if (index === stateIndex) {
                  return newState
                } else {
                  return item
                }
              })
              setTyreStates(newStates)
              setShowStateEditor(false)
              fetchOneTyreSet(currentTyreSet._id).catch((err) => {
                toast.error('Error loading tyre set.')
              })
              toast.success('Tyre state updated.')
            } catch (err) {
              console.error(err)
              toast.error('Error updating tyre state.')
            }
          }}
          onCancel={() => {
            setShowStateEditor(false)
          }}
        />}

    </div >
  )
}

export default TyreSets

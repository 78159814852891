import { Button } from 'primereact/button'
import { InputText } from 'primereact/inputtext'
import { InputNumber } from 'primereact/inputnumber'
import { classNames } from 'primereact/utils'
import { FC, useEffect, useState, useRef } from 'react'
import { Controller, SubmitHandler, useForm } from 'react-hook-form'
import { WorkflowTask, PlatformJob, RescaleJob, RescaleJobAnalysis, RescaleAnalysisVariable, RescaleHardwareVariable } from '../../types/types'
import Modal from '../Modal'
import { Dropdown } from 'primereact/dropdown'
import { Checkbox } from 'primereact/checkbox'
import { DropdownStyle, CheckboxStyle } from '../../utils/styling-constants'
import { TaskTypes } from '../../utils/constants'
import { LoadingIcon, SaveIcon } from '../Icons'
import { DeleteIcon, PlusIcon } from '../Icons'
import IconButton from '../buttons/IconButton'
import { useRecoilValue } from 'recoil'
import { currentTeamState } from '../../utils/state-atoms'
import { PlatformCopyConfig } from '@aero-platform/shared'

type TaskEditorProps = {
  mode: string
  initTask: WorkflowTask
  saving: boolean
  onConfirm: (task: WorkflowTask) => void
  onCancel: () => void
}

const editorTitle = {
  new: 'Create New Task',
  edit: 'View/Edit Task'
}

const TaskEditor: FC<TaskEditorProps> = ({ mode, initTask, saving, onConfirm, onCancel }) => {
  const {
    control,
    setValue,
    handleSubmit,
    formState: { errors }
  } = useForm<WorkflowTask>()
  const currentTeam = useRecoilValue(currentTeamState)
  const [currentTask, setCurrentTask] = useState<WorkflowTask>({ ...initTask } as WorkflowTask)
  const [dependent, setDependent] = useState(false)
  const [taskType, setTaskType] = useState('')
  const formRef = useRef<HTMLFormElement>(null)

  const getFormErrorMessage = (name) => {
    return errors[name] ? (
      <small className="error">{errors[name].message}</small>
    ) : (
      <small className="error">&nbsp;</small>
    )
  }

  const renderTextField = (name: string, label: string, required: boolean | string) => {
    return (
      <Controller
        name={name}
        control={control}
        rules={{ required }}
        render={({ field, fieldState }) => (
          <>
            <label htmlFor={field.name} className="mb-2">
              {label}
            </label>
            <InputText
              id={field.name}
              value={field.value}
              className={classNames({ error: fieldState.error })}
              onChange={(e) => field.onChange(e.target.value)}
            />
            {getFormErrorMessage(field.name)}
          </>
        )}
      />
    )
  }

  const renderNumberField = (name: string, label: string, decimalLimit: number, required: boolean | string) => {
    return (
      <Controller
        name={name}
        control={control}
        rules={{ required }}
        render={({ field, fieldState }) => (
          <>
            <label htmlFor={field.name} className="mb-2">
              {label}
            </label>
            <InputNumber
              id={field.name}
              value={field.value}
              decimalLimit={decimalLimit}
              className={classNames({ error: fieldState.error })}
              onChange={(e) => {
                field.onChange(e.value)
              }}
            />
            {getFormErrorMessage(field.name)}
          </>
        )}
      />
    )
  }

  useEffect(() => {
    if (mode === 'new') {
      setDependent(currentTask.dependent)
      setTaskType(TaskTypes[0].value)
      setValue('type', currentTask.type)
      setValue('dependent', currentTask.dependent)
      setValue('name', currentTask.name)
      setValue('description', currentTask.description)
      setValue('platformJob.platformCopyConfig.sourceAccount', currentTask.platformJob?.platformCopyConfig?.sourceAccount)
      setValue('platformJob.platformCopyConfig.sourceContainerName', currentTask.platformJob?.platformCopyConfig?.sourceContainerName)
      setValue('platformJob.platformCopyConfig.sourceBlobAddr', currentTask.platformJob?.platformCopyConfig?.sourceBlobAddr)
      setValue('platformJob.platformCopyConfig.destinationAccount', currentTask.platformJob?.platformCopyConfig?.destinationAccount)
      setValue('platformJob.platformCopyConfig.destinationContainerName', currentTask.platformJob?.platformCopyConfig?.destinationContainerName)
      setValue('platformJob.platformCopyConfig.destinationBlobAddr', currentTask.platformJob?.platformCopyConfig?.destinationBlobAddr)
    } else if (mode === 'edit') {
      if (currentTask) {
        setDependent(currentTask.dependent)
        setValue('dependent', currentTask.dependent)
        if (currentTask.platformJob) {
          const type = currentTask.type || 'platform'
          setValue('type', type)
          setTaskType(type)
          setValue('name', currentTask.platformJob.name || currentTask.name)
          setValue('description', currentTask.platformJob.description || currentTask.description)
          setValue('platformJob.platformCopyConfig.sourceAccount', currentTask.platformJob?.platformCopyConfig?.sourceAccount)
          setValue('platformJob.platformCopyConfig.sourceContainerName', currentTask.platformJob?.platformCopyConfig?.sourceContainerName)
          setValue('platformJob.platformCopyConfig.sourceBlobAddr', currentTask.platformJob?.platformCopyConfig?.sourceBlobAddr)
          setValue('platformJob.platformCopyConfig.destinationAccount', currentTask.platformJob?.platformCopyConfig?.destinationAccount)
          setValue('platformJob.platformCopyConfig.destinationContainerName', currentTask.platformJob?.platformCopyConfig?.destinationContainerName)
          setValue('platformJob.platformCopyConfig.destinationBlobAddr', currentTask.platformJob?.platformCopyConfig?.destinationBlobAddr)
        } else if (currentTask.rescaleJob) {
          const type = currentTask.type || 'rescale'
          setValue('type', type)
          setTaskType(type)
          setValue('name', currentTask.rescaleJob.name || currentTask.name)
          setValue('description', currentTask.rescaleJob.description || currentTask.description)
          currentTask.rescaleJob.jobanalyses && currentTask.rescaleJob.jobanalyses.forEach((ja, index) => {
            setValue(`rescaleJob.jobanalyses.${index}.analysis.code`, ja.analysis?.code)
            setValue(`rescaleJob.jobanalyses.${index}.analysis.version`, ja.analysis?.version)
            setValue(`rescaleJob.jobanalyses.${index}.hardware.coresPerSlot`, ja.hardware?.coresPerSlot)
            setValue(`rescaleJob.jobanalyses.${index}.hardware.coreType`, ja.hardware?.coreType)
            setValue(`rescaleJob.jobanalyses.${index}.hardware.walltime`, ja.hardware?.walltime)
            setValue(`rescaleJob.jobanalyses.${index}.command`, ja.command)
          })
        }
      }
    }
  }, [])

  const onSubmit: SubmitHandler<WorkflowTask> = (formData: WorkflowTask) => {
    console.log('formData', formData)
    const newTask = {
      ...formData,
      dependent: dependent || false,
      raceTeamId: currentTeam,
    } as WorkflowTask

    if (taskType === 'platform') {
      delete newTask.rescaleJob
      newTask.platformJob.name = newTask.name
      newTask.platformJob.description = newTask.description
      newTask.platformJob.inputFiles = []
      newTask.platformJob.outputFiles = []
      newTask.platformJob.platformCopyConfig = new PlatformCopyConfig(newTask.platformJob.platformCopyConfig)
      newTask.platformJob = new PlatformJob(newTask.platformJob)
    } else if (taskType === 'rescale') {
      delete newTask.platformJob
      newTask.rescaleJob.name = newTask.name
      newTask.rescaleJob.jobanalyses = newTask.rescaleJob.jobanalyses.map(ja => {
        const analysis = new RescaleAnalysisVariable(ja.analysis)
        const hardware = new RescaleHardwareVariable(ja.hardware)
        return new RescaleJobAnalysis({
          analysis,
          hardware,
          command: ja.command
        })
      })
      newTask.rescaleJob = new RescaleJob(newTask.rescaleJob)
    }

    if (mode === 'edit') {
      newTask._id = currentTask._id
    }

    onConfirm && onConfirm(newTask)
  }

  const handleConfirm = () => {
    onCancel && onCancel()
  }

  return (
    <Modal
      size="lg"
      isOpen={true}
      withAction={false}
      easyClose={true}
      onCancel={() => onCancel && onCancel()}
      title={editorTitle[mode]}
      showCancelButton={true}
      zIndex={30}
      onConfirm={handleConfirm}
      footer={(
        <div className="flex items-center justify-end pt-4">
          <Button onClick={() => formRef.current.requestSubmit()} >
            {saving && <LoadingIcon className="fill-white w-4 h-4 animate-spin" />}
            {!saving && <SaveIcon className="fill-white w-4 h-4" />}
            <span className="ml-1">Save</span>
          </Button>
        </div>
      )}

    >
      <div className="relative overflow-y-auto max-h-[calc(100vh-300px)]">
        <form
          ref={formRef}
          onSubmit={handleSubmit(onSubmit)}
          className="w-full flex-col items-stretch space-y-2 mt-8">
          <div className="grid grid-cols-12 gap-4">
            <div className="field-box col-span-2">
              {renderTextField('name', 'Name', 'Name is required')}
            </div>
            <div className="field-box col-span-6">
              {renderTextField('description', 'Description', 'Description is required')}
            </div>
            <div className="field-box col-span-2">
              <Controller
                name="type"
                control={control}
                render={({ field, fieldState }) => (
                  <>
                    <label htmlFor={field.name} className="mb-2">
                      Type
                    </label>
                    <Dropdown
                      value={field.value}
                      pt={{
                        ...DropdownStyle,
                        root: {
                          ...DropdownStyle.root,
                          className: DropdownStyle.root.className + ' max-w-fit'
                        }
                      }}

                      onChange={(e) => {
                        field.onChange(e.value)
                        setTaskType(e.value)
                      }}
                      className={classNames({ error: fieldState.error })}
                      options={TaskTypes}
                      readOnly={mode === 'view'}
                      placeholder="Select Type"
                      emptyMessage="&nbsp;&nbsp;No data"
                    />
                    {getFormErrorMessage(field.name)}
                  </>
                )}
              />
            </div>
            <div className="field-box col-span-2">
              <Controller
                name="dependent"
                control={control}
                render={({ field, fieldState }) => (
                  <>
                    <label htmlFor={field.name} className="opacity-0">
                      Dependent Task?
                    </label>
                    <div className="flex-grow flex items-center">
                      <Checkbox
                        inputId={field.name}
                        pt={CheckboxStyle}
                        checked={field.value}
                        onChange={(e) => {
                          field.onChange(e.checked)
                          setDependent(e.checked)
                        }}></Checkbox>
                      <label htmlFor={field.name} className="ml-2 font-normal">
                        Dependent Task
                      </label>
                    </div>
                  </>
                )}
              />
            </div>
          </div>
          {taskType === 'platform' && <>
            <div className="grid grid-cols-12 gap-4">
              <div className="field-box col-span-2">
                {renderTextField('platformJob.platformCopyConfig.sourceAccount', 'Source Account', taskType === 'platform' ? 'Source account is required' : false)}
              </div>
              <div className="field-box col-span-2">
                {renderTextField('platformJob.platformCopyConfig.sourceContainerName', 'Source Container', taskType === 'platform' ? 'Source container is required' : false)}
              </div>
              <div className="field-box col-span-8">
                {renderTextField('platformJob.platformCopyConfig.sourceBlobAddr', 'Source Blob Address', taskType === 'platform' ? 'Source blob address is required' : false)}
              </div>
            </div>
            <div className="grid grid-cols-12 gap-4">
              <div className="field-box col-span-2">
                {renderTextField('platformJob.platformCopyConfig.destinationAccount', 'Destination Account', taskType === 'platform' ? 'Destination account is required' : false)}
              </div>
              <div className="field-box col-span-2">
                {renderTextField('platformJob.platformCopyConfig.destinationContainerName', 'Destination Container', taskType === 'platform' ? 'Destination container is required' : flase)}
              </div>
              <div className="field-box col-span-8">
                {renderTextField('platformJob.platformCopyConfig.destinationBlobAddr', 'Destination Blob Address', taskType === 'platform' ? 'Destination blob address is required' : flase)}
              </div>
              <div className="field-box col-span-2"></div>
            </div>
          </>}
          {taskType === 'rescale' &&
            <>
              <div className="flex space-x-1 justify-between">
                <div
                  role="button"
                  className="bg-transparent flex space-x-2 items-center !px-0"
                >
                  <span className="text-md text-neutral-80">Job Analyses</span>
                </div>
                <Button
                  type="button"
                  onClick={() => {
                    const analyses = currentTask.rescaleJob?.jobanalyses || []
                    analyses.push({
                      analysis: {},
                      hardware: {}
                    })
                    const updatedTask = {
                      ...currentTask,
                      rescaleJob: {
                        ...currentTask.rescaleJob,
                        jobanalyses: [...analyses]
                      }
                    }
                    console.log('updatedTask', updatedTask)
                    setCurrentTask(updatedTask)
                  }}
                  severity="secondary"
                  className="icon-button hidden"
                  tooltip='Add Analysis'
                  tooltipOptions={{ position: 'bottom' }}
                  data-pr-at='center+45 bottom'
                >
                  <PlusIcon className="fill-white" />
                </Button>
              </div>
              {currentTask.rescaleJob?.jobanalyses && currentTask.rescaleJob.jobanalyses.map((ja, index) => {
                const rowName = `rescaleJob.jobanalyses.${index}`
                return (
                  <div className="flex bg-neutral-50 rounded-md p-4" key={rowName} space-x-4>
                    <div className="flex flex-col items-stretch">
                      <div className="flex space-x-4">
                        <div className="field-box flex-grow basis-0">
                          {renderTextField(`${rowName}.analysis.code`, 'Analysis Code', taskType === 'rescale' ? 'Analysis code is required' : false)}
                        </div>
                        <div className="field-box flex-grow basis-0">
                          {renderTextField(`${rowName}.analysis.version`, 'Analysis Version', taskType === 'rescale' ? 'Analysis version is required' : false)}
                        </div>
                        <div className="field-box flex-grow basis-0">
                          {renderNumberField(`${rowName}.hardware.coresPerSlot`, 'HW Cores Per Slot', 0, taskType === 'rescale' ? 'HW cores per slot is required' : false)}
                        </div>
                        <div className="field-box flex-grow basis-0">
                          {renderTextField(`${rowName}.hardware.coreType`, 'HW Core Type', taskType === 'rescale' ? 'HW core type is required' : false)}
                        </div>
                        <div className="field-box flex-grow basis-0">
                          {renderNumberField(`${rowName}.hardware.walltime`, 'HW Wall Time', 0, taskType === 'rescale' ? 'HW wall time is required' : false)}
                        </div>
                      </div>
                      <div>
                        <div className="field-box flex-grow">
                          {renderTextField(`${rowName}.command`, 'Command', taskType === 'rescale' ? 'Command is required' : false)}
                        </div>
                      </div>
                    </div>
                    <div className="basis-12 flex-grow flex items-center justify-end">
                      <IconButton
                        icon={<DeleteIcon className="fill-white m-0.5 h-3 w-3" />}
                        severity="danger"
                        onClick={() => {
                          const updatedAnalyses = [...currentTask.rescaleJob.jobanalyses]
                          updatedAnalyses.splice(index, 1)
                          setCurrentTask({
                            ...currentTask,
                            rescaleJob: {
                              ...currentTask.rescaleJob,
                              jobanalyses: updatedAnalyses
                            }
                          })
                        }}
                        title="Remove Analysis"
                      />
                    </div>
                  </div>
                )
              })
              }
              <div className="h-2"></div>
            </>
          }
        </form>
      </div>
    </Modal>
  )
}

export default TaskEditor

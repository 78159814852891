import { FilterMatchMode, FilterService } from 'primereact/api'
import {
  DataTableFilterMeta
} from 'primereact/datatable'
import { FC, useEffect, useRef, useState } from 'react'
import { toast } from 'react-hot-toast'
import { useRecoilState, useRecoilValue } from 'recoil'
import {
  useCreateWorkflowTemplate,
  useGetWorkflowTemplate,
  useGetWorkflowTemplates,
  useUpdateWorkflowTemplate
} from '../api/workflow-template-API'
import { LazyTableState, WorkflowSubmissionTemplate } from '../types/types'
import 'primeicons/primeicons.css'
import { Button } from 'primereact/button'
import 'primereact/resources/primereact.css'
import { CopyIcon, PlusIcon } from '../components/Icons'
import { protoService } from '../proto/ProtoService'
import { queryString2JsonString } from '../types/util'
import { currentTeamState, workflowTemplateListState } from '../utils/state-atoms'
import { Dropdown } from 'primereact/dropdown'
import { DropdownStyle } from '../utils/styling-constants'
import SaveButton from '../components/buttons/SaveButton'
import { Controller, SubmitHandler, useForm } from 'react-hook-form'
import { InputText } from 'primereact/inputtext'
import { classNames } from 'primereact/utils'
import WorkflowTemplateEditor from '../components/workflow/WorkflowTemplateEditor'
import { cloneWorkflow } from '../types/util'


const defaultFilters: DataTableFilterMeta = {
  name: { value: null, matchMode: FilterMatchMode.CONTAINS },
  workflowTemplateId: { value: null, matchMode: FilterMatchMode.CONTAINS }
}

// The rule argument should be a string in the format "custom_[field]".
FilterService.register('custom_workflowTemplateId', (value, filters) => {
  return value && value.toString().includes(filters)
});


const WorkflowTemplates: FC = () => {
  const currentTeam = useRecoilValue(currentTeamState)
  const [globalWorkflowTemplate, setGlobalWorkflowTemplate] = useRecoilState(workflowTemplateListState)
  const formRef = useRef<HTMLFormElement>(null)

  const [filters, setFilters] = useState<DataTableFilterMeta>({
    workflowTemplateId: { value: null, matchMode: FilterMatchMode.CUSTOM },
  });
  const [mode, setMode] = useState('')
  const [currentWorkflowTemplate, setCurrentWorkflowTemplate] = useState<WorkflowSubmissionTemplate>()
  const [updatedWorkflowTemplate, setUpdatedWorkflowTemplate] = useState<WorkflowSubmissionTemplate>()
  const [{ loading }, fetchWorkflowTemplates] = useGetWorkflowTemplates()
  const [{ loading: loadingOneTemplate }, fetchOneWorkflowTemplate] = useGetWorkflowTemplate()
  const [{ loading: creating }, createWorkflowTemplate] = useCreateWorkflowTemplate()
  const [{ loading: updating }, updateWorkflowTemplate] = useUpdateWorkflowTemplate()

  const [workflowTemplates, setWorkflowTemplates] = useRecoilState<WorkflowSubmissionTemplate[]>(workflowTemplateListState)
  const [totalRecords, setTotalRecords] = useState<number>(10)
  const [lazyState, setLazyState] = useState<LazyTableState>({
    first: 0,
    rows: 0,
    page: 1,
    sortField: 'timeCreated',
    sortOrder: -1,
    filters: defaultFilters,
    sumlvl: 'workflowTemplateStatesList:0,workflowTemplateList:0,tyreSetList:0,cfdSequenceList:0,method:0'
  })

  const {
    control,
    handleSubmit,
    setValue,
    reset,
    formState: { errors }
  } = useForm<WorkflowSubmissionTemplate>()


  const loadFullTemplate = async (id: string, updateTemplateList: boolean) => {
    try {
      const tplData = await fetchOneWorkflowTemplate(id)
      if (tplData.data.protoBytesList && tplData.data.protoBytesList.length > 0) {
        const workflowTemplate = protoService.deSerialize(tplData.data.protoBytesList[0], 'workflowsubmissiontemplate') as WorkflowSubmissionTemplate
        console.log(workflowTemplate)
        const tmp = {
          ...workflowTemplate,
          masterTemplates: workflowTemplate.masterTemplates || [],
          templateWorkflows: workflowTemplate.templateWorkflows || [],
        } as WorkflowSubmissionTemplate
        if (tmp.masterTemplates.length > 0 && tmp.masterTemplates[0]._id) {
          // load master details from DB
          const tplData = await fetchOneWorkflowTemplate(tmp.masterTemplates[0]._id)
          const masterWorkflowTemplate = protoService.deSerialize(tplData.data.protoBytesList[0], 'workflowsubmissiontemplate') as WorkflowSubmissionTemplate
          tmp.masterTemplates[0] = masterWorkflowTemplate
        }
        setCurrentWorkflowTemplate(tmp)
        if (updateTemplateList) {
          if (mode === 'edit') {
            setWorkflowTemplates( // update template list
              workflowTemplates.map((item) => {
                if (item._id === id) {
                  return {
                    _id: id,
                    name: tmp.name,
                    description: tmp.description
                  } as WorkflowSubmissionTemplate
                } else {
                  return item
                }
              })
            )
          } else if (mode === 'new') { // update template list
            setWorkflowTemplates([tmp, ...workflowTemplates])
          }
        }

        setMode('edit')
        reset()
        setValue('name', workflowTemplate.name)
        setValue('description', workflowTemplate.description)
        return tmp
      }
    } catch (err) {
      console.error(err)
      toast.error('Error loading workflow template.')
    }
  }

  const handleItemSelected = async (workflowTemplate) => {
    if (!workflowTemplate) {
      return
    }
    const template = await loadFullTemplate(workflowTemplate._id, false)
    console.log(template)
    if (template) {
      setUpdatedWorkflowTemplate(template)
    }
  }

  useEffect(() => {
    fetchWorkflowTemplates().then((res) => {
      const workflowTemplateData = res.data
      if (workflowTemplateData) {
        const workflowTemplateList = workflowTemplateData.protoBytesList.map((item: string) => {
          const result = protoService.deSerialize(item, 'workflowsubmissiontemplate')
          return result
        })
        workflowTemplateList && setWorkflowTemplates(workflowTemplateList)
        setGlobalWorkflowTemplate(workflowTemplateList)
      }
    }).catch((err) => {
      console.error(err)
      toast.error('Error loading workflow templates.')
    })
  }, [])

  const getFormErrorMessage = (name) => {
    return errors[name] ? (
      <small className="error">{errors[name].message}</small>
    ) : (
      <small className="error">&nbsp;</small>
    )
  }

  const onSubmit: SubmitHandler<WorkflowSubmissionTemplate> = async (formData: WorkflowSubmissionTemplate) => {
    // validate the data
    if (!formData.name) {
      toast.error('Please enter a name.')
      return
    }
    if (!formData.description) {
      toast.error('Please enter a description.')
      return
    }
    // prepare new template
    const newWorkflowTemplate = new WorkflowSubmissionTemplate(formData)
    if (updatedWorkflowTemplate) {
      if (updatedWorkflowTemplate.masterTemplates) {
        newWorkflowTemplate.masterTemplates = updatedWorkflowTemplate.masterTemplates.map((item) => {
          return WorkflowSubmissionTemplate.fromObject({
            _id: item._id,
          } as WorkflowSubmissionTemplate)
        })
      }

      if (updatedWorkflowTemplate.templateWorkflows) {
        newWorkflowTemplate.templateWorkflows = updatedWorkflowTemplate.templateWorkflows.map((item) => {
          return cloneWorkflow(item)
        })
      } else {
        updatedWorkflowTemplate.templateWorkflows = []
      }
    }


    if (mode === 'new') {  // create new workflow template
      newWorkflowTemplate.raceTeamId = currentTeam
      newWorkflowTemplate._id = ''
      const dataToPost = protoService.encodePayload(newWorkflowTemplate, 'workflowsubmissiontemplate')
      try {
        const res = await createWorkflowTemplate({ data: dataToPost })
        if (res.data.protoBytesList && res.data.protoBytesList.length > 0) {
          const savedWorkflowTemplate: WorkflowSubmissionTemplate = protoService.deSerialize(
            res.data.protoBytesList[0],
            'workflowsubmissiontemplate'
          )
          loadFullTemplate(savedWorkflowTemplate._id, true)
          // setWorkflowTemplates([...workflowTemplates, savedWorkflowTemplate])
          // setCurrentWorkflowTemplate(savedWorkflowTemplate)
          setGlobalWorkflowTemplate([...globalWorkflowTemplate, savedWorkflowTemplate])
          toast.success('Workflow template created.')
          setMode('edit')
        }
      } catch (err) {
        console.error(err)
        toast.error('Error creating workflow template.')
      }
    } else if (mode === 'edit') { // update existing workflow template
      if (!currentWorkflowTemplate || !currentWorkflowTemplate._id) {
        return
      }
      newWorkflowTemplate._id = currentWorkflowTemplate._id
      const dataToPost = protoService.encodePayload(newWorkflowTemplate, 'workflowsubmissiontemplate')
      try {
        await updateWorkflowTemplate({
          id: currentWorkflowTemplate._id,
          data: dataToPost
        } as any)
        loadFullTemplate(currentWorkflowTemplate._id, true)
        toast.success('Workflow template updated.')
      } catch (err) {
        console.error(err)
        toast.error('Error updating workflow template.')
      }
    }
  }

  const handleCreateNewWorkflowTemplate = () => {
    if (mode === 'new') {
      return
    }
    reset()
    setMode('new')
    setValue('name', '')
    setValue('description', '')
    setCurrentWorkflowTemplate({
      masterTemplates: [],
      templateWorkflows: [],
    } as WorkflowSubmissionTemplate)
  }

  const makeQuery = (state) => {
    let query
    console.log("WorkflowTemplate state:", state)

    if (state.filters.name['value']) {
      const nameSearch = { $regex: state.filters.name['value'], $options: 'i' }
      const q = 'name=' + encodeURIComponent(JSON.stringify(nameSearch))
      query = query ? `${query}&${q}` : q
    }

    if (state.filters.workflowTemplateId['value']) {
      const q = 'workflowTemplateId=' + state.filters.workflowTemplateId['value']
      query = query ? `${query}&${q}` : q
    }

    query = queryString2JsonString(query)
    query = query ? `query=${query}&limit=${state.rows}` : `limit=${state.rows}`
    query = `${query}&skip=0&countTotal=true`
    if (state.sortField) {
      const sort = {
        [state.sortField]: state.sortOrder
      }
      query = `${query}&sort=${JSON.stringify(sort)}`
    }

    return query
  }

  useEffect(() => {
    /*
    const query = makeQuery(lazyState)
    fetchWorkflowTemplates(query).catch((err) => {
      console.error(err)
      toast.error('Error loading workflowTemplates.')
    })
      */
  }, [lazyState])

  const handleCopyWorkflowTemplate = (e) => {
    if (mode === 'new') {
      toast.error('Cannot duplicate while creating a new workflow template.')
      return
    }
    if (!currentWorkflowTemplate || !currentWorkflowTemplate._id) {
      toast.error('Please select a workflow template to duplicate.')
      return
    }
    setUpdatedWorkflowTemplate({
      ...currentWorkflowTemplate
    } as WorkflowSubmissionTemplate)
    reset()
    setMode('new')
    setValue('name', '')
    setValue('description', '')
  }


  return (
    <div className="basis-0 grow flex-col items-stretch mt-6 min-w-full">
      <div className="flex items-center justify-between pb-4">
        <h1 className="page-title">Workflow Templates</h1>
        <Button onClick={handleCreateNewWorkflowTemplate} severity="secondary" className="icon-button" tooltip='Create New Template' tooltipOptions={{ position: "bottom" }} data-pr-at='center+40 bottom'>
          <PlusIcon className="fill-white" />
        </Button>
      </div>
      <div className="grid">
        <div className="flex items-center space-x-2 col-span-1">
          <Dropdown
            filter
            loading={loading}
            filterBy="name"
            filterMatchMode="contains"
            showFilterClear
            onFilter={(e) => {
              setLazyState({
                ...lazyState,
                filters: {
                  ...lazyState.filters,
                  name: { value: e.filter, matchMode: FilterMatchMode.CONTAINS }
                }
              })
            }}
            value={currentWorkflowTemplate}
            emptyMessage="No TyreSets Found"
            pt={{
              ...DropdownStyle,
              root: {
                ...DropdownStyle.root,
                className: DropdownStyle.root.className + ' !min-w-[30%] !max-w-[50%]'
              },
            }}
            optionValue="_id"
            optionLabel="name"
            useOptionAsValue
            options={workflowTemplates}
            valueTemplate={(option) => {
              let value
              if (mode === 'new') {
                value = 'New Workflow Template'
              } else if (option) {
                value = option.name
              } else {
                value = (currentWorkflowTemplate && currentWorkflowTemplate.name) || 'Select Workflow Template'
              }
              return <div>{value}</div>
            }}
            itemTemplate={(option) => <div>{option && option._id ? option.name : ''}</div>}
            onChange={(e) => handleItemSelected(e.value)}
            placeholder="Select  Workflow Template"
          />
          <Button
            type="button"
            onClick={handleCopyWorkflowTemplate}
            disabled={loading || creating || updating}
            className="icon-button hidden"
            tooltip='Duplicate  Workflow Template'
            tooltipOptions={{ position: "bottom" }}
            data-pr-at='center+5 bottom'>
            <CopyIcon className="fill-white w-4 h-4" />
          </Button>
          <SaveButton loading={creating || updating}
            onClick={() => formRef.current.requestSubmit()}
            title="Save  Workflow Template" />
        </div>
        {!currentWorkflowTemplate && <div className="text-center p-40 text-lg">Please select a workflow from the list or create a new one to start.</div>}
        {currentWorkflowTemplate && (<>
          <form
            ref={formRef}
            onSubmit={handleSubmit(onSubmit)}
            className="grid grid-cols-3 space-x-2 col-span-1 mt-4"
          >
            <div className="field-box col-span-1">
              <Controller
                name="name"
                control={control}
                disabled={updating || !currentWorkflowTemplate}
                rules={{ required: 'Name is required.' }}
                render={({ field, fieldState }) => (
                  <>
                    <label htmlFor={field.name} className="mb-2">
                      Template Name
                    </label>
                    <InputText
                      id={field.name}
                      {...field}
                      className={classNames({ error: fieldState.error })}
                    />
                    {getFormErrorMessage(field.name)}
                  </>
                )}
              />
            </div>

            <div className="field-box col-span-2">
              <Controller
                name="description"
                control={control}
                disabled={updating || !currentWorkflowTemplate}
                rules={{ required: 'Description is required.' }}
                render={({ field, fieldState }) => (
                  <>
                    <label htmlFor={field.name} className="mb-2">
                      Description
                    </label>
                    <InputText
                      id={field.name}
                      {...field}
                      className={classNames({ error: fieldState.error })}
                    />
                    {getFormErrorMessage(field.name)}
                  </>
                )}
              />
            </div>
          </form>
          <WorkflowTemplateEditor
            loadingTemplate={loadingOneTemplate}
            initWorkflowTemplate={currentWorkflowTemplate}
            onChange={(template) => {
              setUpdatedWorkflowTemplate(template)
            }}
          />
        </>)
        }
      </div>
    </div>
  )
}

export default WorkflowTemplates

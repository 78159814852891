import { WorkflowTask } from "../../types/types"
import Droppable from "../dnd/Droppable"
import Draggable from "../dnd/Draggable"

type WorkflowTaskNodeProps = {
  task: WorkflowTask
  droppable?: boolean
  draggable?: boolean
  droppableId?: string
}

const WorkflowTaskNode: React.FC<WorkflowTaskNodeProps> = ({ task, droppable, draggable, droppableId }) => {
  const renderPureTaskNode = (task) => {
    return (
      <div className="node task-node">
        {(task.platformJob && task.platformJob.name) || (task.rescaleJob && task.rescaleJob.name) || task.name}
      </div>
    )
  }
  const renderTaskNode = (draggable, draggableId, task) => {
    return draggable ? (
      <Draggable id={draggableId} data={task}>
        {renderPureTaskNode(task)}
      </Draggable>
    ) : (
      renderPureTaskNode(task)
    )
  }

  if (droppable) {
    return (
      <Droppable type="task" id={droppableId || ''} data={task}>
        {renderTaskNode(draggable, droppableId, task)}
      </Droppable>
    )
  } else {
    return (
      renderTaskNode(draggable, droppableId, task)
    )
  }
}

export default WorkflowTaskNode

import { UseAxiosResult } from 'axios-hooks'
import { useAxiosClient } from './configs/axios-setup'

export interface IRequestError {
  status: number
  text: string
}

export const useGetWorkflowTemplates = (): UseAxiosResult => {
  const [{ data, loading, error }, doFetch] = useAxiosClient(
    {
      url: `/WorkflowSubmissionTemplates`,
      method: 'GET'
    },
    { manual: true, autoCancel: false, useCache: false }
  )

  return [
    { data, loading, error },
    doFetch,
    () => {
      return
    }
  ]
}
export const useGetWorkflowTemplate = (): UseAxiosResult => {
  const [{ data, loading, error }, doFetch] = useAxiosClient(
    {
      method: 'GET'
    },
    { manual: true, autoCancel: false, useCache: false }
  )

  const refetch = (id) => {
    return doFetch({
      url: `/WorkflowSubmissionTemplates/${id}`,
    })
  }

  return [
    { data, loading, error },
    refetch,
    () => {
      return
    }
  ]
}

export const useCreateWorkflowTemplate = (): UseAxiosResult => {
  const [{ data, loading, error }, doFetch] = useAxiosClient(
    {
      url: '/WorkflowSubmissionTemplates',
      method: 'POST'
    },
    { manual: true, autoCancel: false, useCache: false }
  )

  return [
    { data, loading, error },
    doFetch,
    () => {
      return
    }
  ]
}

export const useUpdateWorkflowTemplate = (): UseAxiosResult => {
  const [{ data, loading, error }, doFetch] = useAxiosClient(
    {
      method: 'PUT'
    },
    { manual: true, autoCancel: false, useCache: false }
  )

  const refetch = ({ id, data }: any) => {
    return doFetch({
      url: `/WorkflowSubmissionTemplates/${id}`,
      data
    })
  }

  return [
    { data, loading, error },
    refetch,
    () => {
      return
    }
  ]
}



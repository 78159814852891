import { FilterMatchMode } from 'primereact/api'
import { Button } from 'primereact/button'
import { DataTableFilterMeta } from 'primereact/datatable'
import { Dropdown } from 'primereact/dropdown'
import { FC, useEffect, useState } from 'react'
import { toast } from 'react-hot-toast'
import { useRecoilState, useRecoilValue } from 'recoil'
import { useCreateWorkflowTemplate, useGetWorkflowTemplate, useGetWorkflowTemplates, useUpdateWorkflowTemplate } from '../api/workflow-template-API'
import { CopyIcon, PlusIcon } from '../components/Icons'
import { protoService } from '../proto/ProtoService'
import { LazyTableState, Workflow, WorkflowSubmissionTemplate } from '../types/types'
import { queryString2JsonString } from '../types/util'
import { DefaultPageSize, DummyWorkflow, workflowTemplate } from '../utils/constants'
import { currentTeamState, workflowTemplateListState } from '../utils/state-atoms'
import SaveButton from '../components/buttons/SaveButton'
import { SubmitHandler, useForm } from 'react-hook-form'

const defaultFilters: DataTableFilterMeta = {
  name: { value: null, matchMode: FilterMatchMode.CONTAINS },
  workflowId: { value: null, matchMode: FilterMatchMode.EQUALS },
  status: { value: null, matchMode: FilterMatchMode.EQUALS }
}

const Workflows: FC = () => {
  const currentTeam = useRecoilValue(currentTeamState)
  const [mode, setMode] = useState('')
  const [{ loading, data: workflowData }, fetchWorkflowTemplates] = useGetWorkflowTemplates()
  const [{ }, fetchOneWorkflowTemplate] = useGetWorkflowTemplate()
  const [{ loading: creating }, createWorkflowTemplate] = useCreateWorkflowTemplate()
  const [{ loading: updating }, updateWorkflowTemplate] = useUpdateWorkflowTemplate()

  const [globalWorkflowTemplate, setGlobalWorkflowTemplate] = useRecoilState(workflowTemplateListState)

  const [workflowTemplates, setWorkflowTemplates] = useState<WorkflowSubmissionTemplate[]>([])
  const [currentWorkflowTemplate, setCurrentWorkflowTemplate] = useState<WorkflowSubmissionTemplate>()
  const [currentJsonData, setCurrentJsonData] = useState({})
  const [lazyState, setlazyState] = useState<LazyTableState>({
    first: 0,
    rows: DefaultPageSize,
    page: 1,
    sortField: 'name',
    sortOrder: -1,
    filters: defaultFilters,
    sumlvl: 'vehicleStatesList:0,geometryList:0,workflowTemplateList:0,cfdSequenceList:0,workflow:0'
  })

  const {
    control,
    handleSubmit,
    setValue,
    reset,
    formState: { errors }
  } = useForm<WorkflowSubmissionTemplate>()

  useEffect(() => {
    if (workflowData) {
      console.log(workflowData)
      setWorkflowTemplates(workflowData)
      const workflowList = workflowData.protoBytesList.map((item: string) => {
        const result = protoService.deSerialize(item, 'workflow')
        return result
      })
      console.log(workflowList)
      workflowList && setWorkflowTemplates(workflowList)
    }
  }, [workflowData])

  const makeQuery = (state) => {
    let query
    console.log(state)

    if (state.filters.name['value']) {
      const nameSearch = { $regex: state.filters.name['value'], $options: 'i' }
      const q = 'name=' + encodeURIComponent(JSON.stringify(nameSearch))
      query = query ? `${query}&${q}` : q
    }

    if (state.filters.workflowId['value']) {
      const q = 'workflowId=' + state.filters.workflowId['value']
      query = query ? `${query}&${q}` : q
    }

    if (state.filters.status['value']) {
      const q = 'status=' + state.filters.status['value']
      query = query ? `${query}&${q}` : q
    }

    query = queryString2JsonString(query)
    query = query ? `query=${query}&limit=${state.rows}` : `limit=${state.rows}`
    query = `${query}&skip=${state.first}&countTotal=true`
    if (state.sortField) {
      const sort = {
        [state.sortField]: state.sortOrder
      }
      query = `${query}&sort=${JSON.stringify(sort)}`
    }

    return query
  }

  useEffect(() => {
    const query = makeQuery(lazyState)
    fetchWorkflowTemplates(query).catch((err) => {
      console.error(err)
      toast.error('Error loading workflows.')
    })
  }, [lazyState])

  const handleItemSelected = (workflowTemplate) => {
    if (!workflowTemplate) {
      return
    }
    fetchOneWorkflowTemplate(workflowTemplate._id).catch((err) => {
      toast.error('Error loading tyre set.')
    })

    setCurrentWorkflowTemplate(workflowTemplate)
    setMode('edit')
    reset()
    setValue('name', workflowTemplate.name)
    setValue('description', workflowTemplate.description)
  }

  const onSubmit: SubmitHandler<WorkflowSubmissionTemplate> = async (formData: WorkflowSubmissionTemplate) => {
    if (mode === 'new') {  // create new tyre set
      const newWorkflowTemplate = new WorkflowSubmissionTemplate(formData)
      newWorkflowTemplate.raceTeamId = currentTeam
      const dataToPost = protoService.encodePayload(newWorkflowTemplate, 'workflowsubmissiontemplate')
      try {
        const res = await createWorkflowTemplate({ data: dataToPost })
        if (res.data.protoBytesList && res.data.protoBytesList.length > 0) {
          const savedWorkflowTemplate: WorkflowSubmissionTemplate = protoService.deSerialize(res.data.protoBytesList[0], 'workflowsubmissiontemplate')
          setWorkflowTemplates([...workflowTemplates, savedWorkflowTemplate])
          setCurrentWorkflowTemplate(savedWorkflowTemplate)
          setGlobalWorkflowTemplate([...globalWorkflowTemplate, savedWorkflowTemplate])
          toast.success('Tyre set created.')
          setMode('edit')
        }
      } catch (err) {
        console.error(err)
        toast.error('Error creating tyre set.')
      }
    } else if (mode === 'edit') { // update existing tyre set
      if (!currentWorkflowTemplate || !currentWorkflowTemplate._id) {
        return
      }
      const newWorkflowTemplate = new WorkflowSubmissionTemplate(formData)
      newWorkflowTemplate._id = currentWorkflowTemplate._id
      newWorkflowTemplate.raceTeamId = currentTeam
      const dataToPost = protoService.encodePayload(newWorkflowTemplate, 'tyreset')
      try {
        await updateWorkflowTemplate({
          id: currentWorkflowTemplate._id,
          data: dataToPost
        } as any)
        setWorkflowTemplates(
          workflowTemplates.map((item) => {
            if (item._id === currentWorkflowTemplate._id) {
              return newWorkflowTemplate
            } else {
              return item
            }
          })
        )
        setCurrentWorkflowTemplate(newWorkflowTemplate)
        toast.success('Tyre set updated.')
      } catch (err) {
        console.error(err)
        toast.error('Error updating tyre set.')
      }
    }

  }

  const handleCreateNewWorkflow = () => {
    if (mode === 'new') {
      return
    }
    reset()
    setMode('new')
  }

  const handleCopyWorkflow = (e) => {
    if (mode === 'new') {
      toast.error('Cannot duplicate while creating a new workflow.')
      return
    }
    if (!currentWorkflowTemplate || !currentWorkflowTemplate._id) {
      toast.error('Please select a workflow to duplicate.')
      return
    }

    setMode('new')
  }

  const handleWorkflowSelected = (workflow) => {
    console.log(workflow)
    setCurrentWorkflowTemplate({ ...workflow } as WorkflowSubmissionTemplate)
    try {
      workflow.configJSON && setCurrentJsonData(JSON.parse(workflow.configJSON))
    } catch (err) {
      console.error(err)
      console.log(workflow.configJSON)
      toast.error('Error parsing JSON data.')
    }
    setMode('edit')
  }

  return (
    <div className="basis-0 grow flex flex-col items-stretch mt-6 min-w-full space-y-4">
      <div className="flex items-center justify-between pb-4">
        <h1 className="page-title">Workflows</h1>
        <Button
          onClick={handleCreateNewWorkflow}
          severity="secondary"
          className="icon-button"
          disabled={mode === 'new'}
          tooltip='Create New Workflow'
          tooltipOptions={{ position: 'bottom' }}
          data-pr-at='center+50 bottom'
        >
          <PlusIcon className="fill-white" />
        </Button>
      </div>
      <div className='grid grid-cols-2 gap-6'>
        <div className="flex flex-col items-stretch mt-6 min-w-full space-y-4">
          <div className="flex items-center space-x-2">
            <Dropdown
              filter
              filterBy="name"
              filterMatchMode="contains"
              showFilterClear
              onFilter={(e) => {
                setlazyState({
                  ...lazyState,
                  filters: {
                    ...lazyState.filters,
                    name: { value: e.filter, matchMode: FilterMatchMode.CONTAINS }
                  }
                })
              }}
              value={currentWorkflowTemplate}
              emptyMessage="No Workflows Found"
              pt={{
                root: {
                  className: 'min-w-[50%] max-w-[50%]'
                },
                input: {
                  className: 'py-1'
                }
              }}
              optionValue="_id"
              optionLabel="name"
              useOptionAsValue
              options={workflowTemplates}
              valueTemplate={(option) => {
                let value
                if (mode === 'new') {
                  value = 'New Workflow'
                } else if (option) {
                  value = option.name
                } else {
                  value = (currentWorkflowTemplate && currentWorkflowTemplate.name) || 'Select workflow'
                }
                return <div>{value}</div>
              }}
              itemTemplate={(option) => <div>{option && option._id ? option.name : ''}</div>}
              onChange={(e) => handleWorkflowSelected(e.value)}
              placeholder="Select Workflow 1"
            />
            <Button onClick={handleCopyWorkflow} className="icon-button" tooltip='Duplicate Workflow' tooltipOptions={{ position: 'bottom' }} data-pr-at='center+5 bottom'>
              <CopyIcon className="fill-white stroke-white w-4 h-4" />
            </Button>

            <SaveButton loading={creating || updating} title="Save Workflow" />
          </div>
          <div className='flex-grow overflow-auto border rounded-md p-2 border-gray-400' style={{ maxHeight: 'calc(100vh - 315px)' }}>
          </div>
        </div>
      </div >
      <div className="h-0.5"></div>
    </div >
  )
}

export default Workflows

import { UseAxiosResult } from 'axios-hooks'
import { AxiosResponseProtoData } from '../types/types'
import { apiClient, useAxiosClient } from './configs/axios-setup'

export interface IRequestError {
  status: number
  text: string
}

export const useGetRescaleJobStatus = (): UseAxiosResult => {
  const [{ data, loading, error }, doFetch] = useAxiosClient(
    {
      baseURL: process.env.REACT_APP_RESCALE_API_BASE_URL,
      url: `/jobs/`,
      method: 'GET'
    },
    { manual: true, autoCancel: false, useCache: false }
  )

  return [
    { data, loading, error },
    doFetch,
    () => {
      return
    }
  ]
}

export const useGetSubmissionsByProject = (projectId: string, sumlvl: string): UseAxiosResult => {
  const [{ data, loading, error }, doFetch] = useAxiosClient(
    {
      url: `/WorkflowSubmissions/projectId=${projectId}&_sumlvl=${encodeURIComponent(sumlvl)}`,
      method: 'GET'
    },
    { manual: true, autoCancel: false, useCache: false }
  )

  return [
    { data, loading, error },
    doFetch,
    () => {
      return
    }
  ]
}

export const useGetSubmissions = (): UseAxiosResult => {
  const [{ data, loading, error }, doFetch] = useAxiosClient(
    {
      url: '/WorkflowSubmissions',
      method: 'GET'
    },
    { manual: true, autoCancel: false, useCache: false }
  )

  const refetch = (query) => {
    return doFetch({
      url: '/WorkflowSubmissions' + (query ? `?${query}` : '')
    })
  }

  return [
    { data, loading, error },
    refetch,
    () => {
      return
    }
  ]
}

export const useGetSubmission = (): UseAxiosResult<AxiosResponseProtoData> => {
  const [{ data, loading, error }, doFetch] = useAxiosClient(
    {
      method: 'GET'
    },
    { manual: true, autoCancel: false, useCache: false }
  )

  const refetch = (id) => {
    return doFetch({
      url: `/WorkflowSubmissions/${id}`,
    })
  }

  return [
    { data, loading, error },
    refetch,
    () => {
      return
    }
  ]
}

export const useCommitRun = (): UseAxiosResult<AxiosResponseProtoData> => {
  const [{ data, loading, error }, doFetch] = useAxiosClient(
    {
      baseURL: process.env.REACT_APP_WORKFLOW_SUBMISSION_API_BASE_URL,
      method: 'POST'
    },
    { manual: true, autoCancel: false, useCache: false }
  )

  const refetch = (runId) => {
    return doFetch({
      url: `/WorkflowSubmission/${runId}/workflowSubmit/`,
    })
  }

  return [
    { data, loading, error },
    refetch,
    () => {
      return
    }
  ]
}

export const useCancelTask = (): UseAxiosResult<any> => {
  const [{ data, loading, error }, doFetch] = useAxiosClient(
    {
      baseURL: process.env.REACT_APP_WORKFLOW_SUBMISSION_API_BASE_URL,
      method: 'PUT'
    },
    { manual: true, autoCancel: false, useCache: false }
  )

  const refetch = ({runId, runGroupId, workflowId, taskId}: any) => {
    return doFetch({
      url: `/WorkflowSubmission/${runId}/workflowCancel/?workflowRunGroupSysId=${runGroupId}&workflowSystemRunId=${workflowId}&workflowTaskSystemRunId=${taskId}`,
    })
  }

  return [
    { data, loading, error },
    refetch,
    () => {
      return
    }
  ]
}



export const useRecommitRun = (): UseAxiosResult<AxiosResponseProtoData> => {
  const [{ data, loading, error }, doFetch] = useAxiosClient(
    {
      baseURL: process.env.REACT_APP_WORKFLOW_SUBMISSION_API_BASE_URL,
      // url: `/WorkflowSubmission/${submissionId}/workflowResubmit/?workflowId=${workflowId}`,
      method: 'PUT'
    },
    { manual: true, autoCancel: false, useCache: false }
  )

  const refetch = ({ runId, workflowRunGroupSysId, workflowSystemRunId, taskId }: any) => {
    let url = `/WorkflowSubmission/${runId}/workflowResubmit/`

    let param
    if (workflowRunGroupSysId) {
      param = `workflowRunGroupSysId=${workflowRunGroupSysId}`
    }
    if (workflowSystemRunId) {
      param = (param ? (param + '&') : '') + `workflowSystemRunId=${workflowSystemRunId}`
    }
    if (taskId) {
      param = (param ? (param + '&') : '') + `workflowTaskSystemRunId=${taskId}`
    }

    url = `${url}${param ? ('?' + param) : ''}`

    return doFetch({
      url
    })
  }

  return [
    { data, loading, error },
    refetch,
    () => {
      return
    }
  ]
}



export const useAddWorkflowRunGroup = (): UseAxiosResult<AxiosResponseProtoData> => {
  const [{ data, loading, error }, doFetch] = useAxiosClient(
    {
      baseURL: process.env.REACT_APP_WORKFLOW_SUBMISSION_API_BASE_URL,
      // url: `/WorkflowSubmission/${submissionId}/workflowResubmit/?workflowId=${workflowId}`,
      method: 'PUT'
    },
    { manual: true, autoCancel: false, useCache: false }
  )

  const refetch = ({ runId, caseNames }: any) => {
    let url = `/WorkflowSubmission/${runId}/addWorkflowRunGroup/?caseName=${caseNames.join(',')}`

    return doFetch({
      url
    })
  }

  return [
    { data, loading, error },
    refetch,
    () => {
      return
    }
  ]
}


export const useAddStates = (): UseAxiosResult<AxiosResponseProtoData> => {
  const [{ data, loading, error }, doFetch] = useAxiosClient(
    {
      baseURL: process.env.REACT_APP_WORKFLOW_SUBMISSION_API_BASE_URL,
      method: 'PUT'
    },
    { manual: true, autoCancel: false, useCache: false }
  )

  const refetch = ({ runId, states }: any) => {
    const param = encodeURIComponent(states.join(','))
    return doFetch({
      url: `/WorkflowSubmission/${runId}/updateVehicleStates/?statesToAdd=${param}`
    })
  }

  return [
    { data, loading, error },
    refetch,
    () => {
      return
    }
  ]
}

export const useCreateRun = (): UseAxiosResult => {
  const [{ data, loading, error }, doFetch] = useAxiosClient(
    {
      url: '/WorkflowSubmissions',
      method: 'POST'
    },
    { manual: true, autoCancel: false, useCache: false }
  )

  return [
    { data, loading, error },
    doFetch,
    () => {
      return
    }
  ]
}

export const useUpdateWorkflowSubmission = (id: string): UseAxiosResult => {
  const [{ data, loading, error }, doFetch] = useAxiosClient(
    {
      url: `/WorkflowSubmissions/${id}`,
      method: 'PUT'
    },
    { manual: true, autoCancel: false, useCache: false }
  )

  return [
    { data, loading, error },
    doFetch,
    () => {
      return
    }
  ]
}

export const useUpdateWorkflowSubmissionVehicleStates = (id: string): UseAxiosResult => {
  const [{ data, loading, error }, doFetch] = useAxiosClient(
    {
      url: `/WorkflowSubmissions/${id}/states`,
      method: 'PUT'
    },
    { manual: true, autoCancel: false, useCache: false }
  )

  const refetch = ({ runId, data }: any) => {
    return doFetch({
      url: `/WorkflowSubmissions/${runId}/states`,
      data
    })
  }

  return [
    { data, loading, error },
    refetch,
    () => {
      return
    }
  ]
}

export const useAddRunConfigCase = (): UseAxiosResult => {
  const [{ data, loading, error }, doFetch] = useAxiosClient(
    {
      method: 'POST'
    },
    { manual: true, autoCancel: false, useCache: false }
  )

  const refetch = ({ id, data }: any) => {
    return doFetch({
      url: `/WorkflowSubmissions/${id}/cases`,
      data
    })
  }

  return [
    { data, loading, error },
    refetch,
    () => {
      return
    }
  ]
}


export const useDeleteRunConfigCase = (): UseAxiosResult => {
  const [{ data, loading, error }, doFetch] = useAxiosClient(
    {
      method: 'DELETE'
    },
    { manual: true, autoCancel: false, useCache: false }
  )

  const refetch = ({ id, caseName }: any) => {
    return doFetch({
      url: `/WorkflowSubmissions/${id}/cases/${caseName}`,
      data
    })
  }

  return [
    { data, loading, error },
    refetch,
    () => {
      return
    }
  ]
}

export const useUpdateRunConfigCase = (): UseAxiosResult => {
  const [{ data, loading, error }, doFetch] = useAxiosClient(
    {
      method: 'PUT'
    },
    { manual: true, autoCancel: false, useCache: false }
  )

  const refetch = ({ id, data }: any) => {
    return doFetch({
      url: `/WorkflowSubmissions/${id}/cases`,
      data
    })
  }

  return [
    { data, loading, error },
    refetch,
    () => {
      return
    }
  ]
}

/*
export const useCreateWorkflowTask = (): UseAxiosResult => {
  const [{ data, loading, error }, doFetch] = useAxiosClient(
    {
      url: '/WorkflowTasks',
      method: 'POST'
    },
    { manual: true, autoCancel: false, useCache: false }
  )

  return [
    { data, loading, error },
    doFetch,
    () => {
      return
    }
  ]
}

export const useGetWorkflows = (): UseAxiosResult => {
  const [{ data, loading, error }, doFetch] = useAxiosClient(
    {
      url: '/Workflows',
      method: 'GET'
    },
    { manual: true, autoCancel: false, useCache: false }
  )

  return [
    { data, loading, error },
    doFetch,
    () => {
      return
    }
  ]
}

export const useGetWorkflow = (id): UseAxiosResult => {
  const [{ data, loading, error }, doFetch] = useAxiosClient(
    {
      url: `/Workflows/${id}`,
      method: 'GET'
    },
    { manual: true, autoCancel: false, useCache: false }
  )

  return [
    { data, loading, error },
    doFetch,
    () => {
      return
    }
  ]
}

export const useCreateWorkflow = (): UseAxiosResult => {
  const [{ data, loading, error }, doFetch] = useAxiosClient(
    {
      url: '/Workflows',
      method: 'POST'
    },
    { manual: true, autoCancel: false, useCache: false }
  )

  return [
    { data, loading, error },
    doFetch,
    () => {
      return
    }
  ]
}

*/

export async function getSubmissionStatus(id: string) {
  const res = await apiClient.get(`/WorkflowSubmissions/${id}`)
  return res
}

import React, { PropsWithChildren } from 'react'
import { useDroppable } from '@dnd-kit/core'
import { classNames } from 'primereact/utils'

type DroppableProps = {
  id: string
  data: any,
  type: string
}


const Droppable: React.FC<DroppableProps & PropsWithChildren> = ({ id, data, children, type }) => {
  const { isOver, setNodeRef, over, active } = useDroppable({
    id,
    data
  })

  const activeId = active && active.id.toString()
  let classOnHover
  if (!over || !active || !activeId || over.id === activeId) {
    classOnHover = ''
  } else if (type === 'trash' && over && over.id === 'trash') {
    if (active && activeId && (activeId.startsWith('workflow-') || activeId.startsWith('tasknode-'))) {
      classOnHover = 'trash-active'
    } else {
      classOnHover = ''
    }
  } else if (type === 'workflow') {
    if (active && activeId && activeId.startsWith('workflow-')) {
      const [, , activeWorkflowIndex] = activeId.split('-')
      const [, , targetWorkflowIndex] = over.id.split('-')
      if (parseInt(targetWorkflowIndex) - parseInt(activeWorkflowIndex) === 1) {
        classOnHover = ''
      } else {
        classOnHover = classNames('', { 'before:block before:mb-1 before:rounded-md before:h-2 before:bg-gray-600 dark:before:bg-neutral-50  before:content': isOver })
      }
    } else if (active && activeId && activeId.startsWith('task-')) {
      classOnHover = classNames('rounded-md border-2 border-gray-600 dark:border-neutral-50 border-dashed', { 'p-1 border': isOver, 'border-none': !isOver })
    } else {
      classOnHover = ''
    }
  } else if (type === 'task') {
    if (active && activeId) {
      if (activeId.startsWith('task-')) {
        classOnHover = classNames('', { 'before:block before:mb-1 before:rounded-md before:h-2 before:bg-gray-600 dark:before:bg-neutral-50  before:content': isOver })
      } else if (activeId.startsWith('tasknode-')) {
        const [, activeWorkflowId, activeWorkflowIndex, , activeTaskIndex] = activeId.split('-')
        const [, targetWorkflowId, targetWorkflowIndex, , targetTaskIndex] = over.id.split('-')
        if (activeWorkflowId === targetWorkflowId && activeWorkflowIndex === targetWorkflowIndex) {
          // with in workflow
          if (parseInt(targetTaskIndex) - parseInt(activeTaskIndex) === 1) {
            classOnHover = ''
          } else {
            classOnHover = classNames('', { 'before:block before:mb-1 before:rounded-md before:h-2 before:bg-gray-600 dark:before:bg-neutral-50  before:content': isOver })
          }
        } else {
          classOnHover = ''
        }
      } else {
        classOnHover = ''
      }
    } else {
      classOnHover = ''
    }
  }

  return (
    <div ref={setNodeRef} className={classOnHover}>
      {children}
    </div>
  )
}

export default Droppable
